<template>
  <section class="ucp-section" :class="{ 'ucp-section--double': double }">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    components: {},
    props: {
      double: Boolean
    },
    methods: {},
    computed: {}
  }
</script>

<style lang="scss" scoped>
  .ucp-section {
    width: 100vw;

    @include media-breakpoint-up(md) {
      height: 100vh;
    }

    &.ucp-section--double {
      height: 120vh;

      @include media-breakpoint-up(md) {
        width: 220vw;
      }
    }
  }
</style>
