<template>
  <div class="ucp-intro">
    <header class="ucp-header d-md-none">
      <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-header__logo"/>
    </header>

    <div class="ucp-animation ucp-triangle-name">
      <span>{{ data.person_name }}</span>
      {{ data.person_position }}
    </div>

    <!--<div class="ucp-animation ucp-triangle-start">
      {{data.small_1}}
      <span>{{data.small_2}}</span>
    </div>-->

    <!--<div class="ucp-triangle-know-more-large" @click.prevent="callback">
      <span class="text">{{data.more_1}}</span>
      <span class="text text--bold">{{data.more_2}}</span>
    </div>-->

    <div class="ucp-animation ucp-triangle-intro">
      <span class="text">{{ data.big_1 }}</span>
      <span class="large">{{ data.big_2 }}</span>
      <span class="large">{{ data.big_2a }}</span>
      <span class="large yellow">{{ data.big_3 }}</span>
    </div>

    <div class="ucp-animation ucp-start-date">
      <span class="d-md-none">{{ data.start_date_mention }}</span>
      <span class="d-none d-md-block">{{ data.start_date }}</span>
    </div>

    <!--<div class="ucp-animation ucp-triangle-apply d-none d-md-block" @click.prevent="callback">
      <span class="text yellow">{{data.apply_2}}</span>
      <span class="text">{{data.apply_1}}</span>
    </div>-->

    <!--<div class="ucp-animation ucp-triangle-apply" @click.prevent="mobileCallback">
      <span class="text text-small d-none d-md-block">{{ data.start_date_mention }}</span>
      <span class="text yellow">{{ data.apply_2 }}</span>
      <span class="text">{{ data.apply_1 }}</span>
    </div>

    <div class="ucp-animation ucp-triangle-know-more" @click="formCallback">
      <span class="text">{{ data.more_1 }}</span>
      <span class="text yellow">{{ data.more_2 }}</span>
    </div>-->

    <!--<div class="ucp-animation ucp-scroll-discover d-none d-md-block">
      <a href="#" @click="nextSection">
        <span>{{data.bottom_1}}</span>
        {{data.bottom_2}}
      </a>
    </div>-->

    <div class="ucp-animation ucp-scroll-discover d-md-none">
      <a href="#" @click="nextSection">
        <span>{{data.bottom_3}}</span>
        {{data.bottom_4}}
      </a>
    </div>

    <div class="ucp-animation ucp-triangle-green-one"></div>

    <div class="ucp-animation ucp-triangle-green-two"></div>

    <div class="ucp-animation ucp-triangle-red-one"></div>

    <!--<div class="ucp-animation ucp-triangle-red-two"></div>-->

    <div class="ucp-animation ucp-triangle-white d-none d-md-block"></div>

    <div class="ucp-animation ucp-triangle-yellow"></div>

    <div class="ucp-bottom-bar"></div>

  </div>
</template>

<script>
import { isMobileOnly, deviceDetect } from 'mobile-device-detect'

export default {
  components: {},
  props: {
    callback: Function,
    formCallback: Function,
    mobileCallback: Function
  },
  data () {
    return {
      data: {},
      isMobileOnly: isMobileOnly,
      containerId: ''
    }
  },
  methods: {
    loadData: function () {
      import(`@/data/strings/intro.json`).then((data) => {
        this.data = data.default
      })

      return this
    },
    nextSection (e) {
      e.preventDefault()

      if (!this.isMobileOnly) {
        this.callback()
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    // Next step automatically
    setTimeout(() => {
      if (!this.isMobileOnly) this.callback()
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>

.ucp-intro {
  height: 100vh;
  position: relative;


}

.ucp-triangle-name {
  bottom: rem(180);
  display: inline-block;
  left: rem(-120);
  line-height: rem(60);
  position: absolute;
  padding-right: rem(70);
  transform: rotate(-90deg);
  z-index: 2;

  span {
    font-weight: 700;
  }

  @include media-breakpoint-up(md) {
    bottom: rem(20);
    left: rem(80);
    line-height: normal;
    padding-right: 0;
    right: rem(80);
    top: auto;
    transform: rotate(0);
  }
}

.ucp-triangle-start {
  @include svg-standalone(80, theme-color(yellow), normal, true);
  color: theme-color(blue);
  display: none;
  left: 12vw;
  line-height: 1;
  font-size: rem(12);
  padding: rem(22) 0 0 rem(10);
  position: absolute;
  text-transform: uppercase;
  top: 40vh;
  z-index: 1;

  span {
    display: block;
    font-weight: 400;
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(140, theme-color(yellow), normal, true);
    left: 48vw;
    font-size: rem(20);
    padding: rem(40) rem(20) 0 rem(16);
    top: 10vh;
  }
}

.ucp-triangle-intro {
  @include svg-standalone(210, theme-color(blue), large, true);
  bottom: 28vh;
  color: theme-color(white);
  right: 0;
  padding: 44px 30px 0 24px;
  position: absolute;
  text-transform: uppercase;
  transition: transform .8s ease-in-out;

  .text,
  .large,
  .yellow {
    display: block;
  }

  .text {
    color: theme-color(white);
    font-size: rem(13);
    line-height: 1.2;
    padding-right: rem(120);
  }

  .large {
    font-size: rem(22);
    font-weight: 400;
    line-height: 1;
    padding-right: rem(40);
  }

  .yellow {
    font-size: rem(20);
    color: theme-color(yellow);
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(400, theme-color(blue), large, true);
    left: 52vw;
    padding: rem(100) rem(70) rem(100) rem(50);
    top: 17vh;

    .text {
      font-size: rem(20);
    }

    .large,
    .yellow {
      font-size: rem(40);
    }
  }

  @include media-breakpoint-up(lg) {
    @include svg-standalone(500, theme-color(blue), large, true);
    left: 52vw;
    padding: rem(100) rem(70) rem(100) rem(50);
    top: 12vh;

    .text {
      font-size: rem(30);
    }

    .large,
    .yellow {
      font-size: rem(60);
    }
  }

  @include media-breakpoint-up(xl) {
    @include svg-standalone(500, theme-color(blue), large, true);
    left: 52vw;
    padding: rem(100) rem(70) rem(100) rem(50);
    top: 12vh;

    .text {
      font-size: rem(30);
    }

    .large,
    .yellow {
      font-size: rem(60);
    }
  }
}

@keyframes discover {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(#{rem(10)});
  }
  100% {
    opacity: 0;
  }
}

.ucp-scroll-discover {
  bottom: rem(30);
  color: theme-color(white);
  padding: 0 rem(20) 0 0;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  transition: $transition-base;

  a {
    color: theme-color(white);
  }

  span {
    font-weight: 400;
  }

  &::before {
    @include svg-standalone(14, theme-color(white), outline-white, true);
    animation: discover 2s infinite;
    content: '';
    position: absolute;
    right: rem(60);
    top: rem(30);
    transform: rotate(90deg);

    @include media-breakpoint-up(md) {
      right: 0;
      top: rem(12);
    }
  }

  @include media-breakpoint-up(md) {
    bottom: rem(40);
    color: theme-color(black);
    font-size: rem(22);
    right: rem(100);
    transform: none;

    a {
      color: theme-color(black);
    }

    &::before {
      @include svg-standalone(14, theme-color(black), outline, true);
      transform: none;
    }
  }
}

.ucp-triangle-green-one,
.ucp-triangle-green-two {
  @include svg-standalone(20, theme-color(green), normal, true);
  position: absolute;
}

.ucp-triangle-green-one {
  display: none;
  left: 49vw;
  top: 75vh;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.ucp-triangle-green-two {
  left: 12vw;
  top: 56vh;

  @include media-breakpoint-up(md) {
    left: 80vw;
    top: 10vh;
  }
}

.ucp-triangle-red-one,
.ucp-triangle-red-two {
  position: absolute;
}

.ucp-triangle-red-one {
  @include svg-standalone(42, theme-color(yellow), normal, true);
  left: 77vw;
  top: 14vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(42, theme-color(red), normal, true);
  }
}

.ucp-triangle-red-two {
  @include svg-standalone(32, theme-color(red), normal, true);
  display: none;
  left: 64vw;
  top: 80vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(36, theme-color(red), normal, true);
    left: 78vw;
    top: 60vh;
  }
}

.ucp-triangle-white {
  @include svg-standalone(28, theme-color(white), normal, true);
  left: 16vw;
  position: absolute;
  top: 73vh;

  @include media-breakpoint-up(md) {
    left: 55vw;
    position: absolute;
    top: 64vh;
  }
}

.ucp-triangle-yellow {
  @include svg-standalone(28, theme-color(red), normal, true);
  left: 85vw;
  position: absolute;
  top: 38vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(28, theme-color(yellow), normal, true);
    left: 56vw;
    position: absolute;
    top: 19vh;
  }
}

.ucp-start-date {
  bottom: rem(50);
  color: theme-color(black);
  font-size: rem(12);
  font-weight: $font-weight-normal;
  left: 50%;
  position: absolute;
  right: auto;
  text-align: center;
  transform: translateX(-50%);
  width: auto;
  z-index: 2;

  @include media-breakpoint-up(md) {
    bottom: rem(20);
    color: theme-color(black);
    font-size: rem(14);
    left: auto;
    right: rem(80);
    text-align: left;
    transform: none;
  }
}

.ucp-triangle-apply {
  @include svg-standalone(105, theme-color(red), normal, true);
  bottom: 20vh;
  cursor: pointer;
  padding: rem(37) rem(14) rem(14) rem(14);
  position: absolute;
  right: rem(130);
  text-align: right;
  transform: rotate(180deg);

  .text {
    color: theme-color(white);
    display: block;
    font-size: rem(16);
    line-height: 1;
    transform: rotate(180deg);
  }

  .text-small {
    display: block;
    font-size: 70%;
    padding-left: rem(36);
    padding-top: rem(10);
  }

  .yellow {
    color: theme-color(yellow);
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(150, theme-color(red), normal, true);
    bottom: 16vh;
    padding-top: rem(31);
    right: 24vw;

    .text {
      font-size: rem(22);
    }

    .text-small {
      font-size: rem(11);
    }
  }
}

.ucp-triangle-know-more {
  @include svg-standalone(105, theme-color(blue), normal, true);
  bottom: 20vh;
  cursor: pointer;
  padding: rem(36) rem(14) rem(14) rem(14);
  position: absolute;
  right: rem(20);

  .text {
    color: theme-color(white);
    display: block;
    font-size: rem(16);
    line-height: 1;
  }

  .yellow {
    color: theme-color(yellow);
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(150, theme-color(blue), normal, true);
    bottom: 16vh;
    padding-top: rem(43);
    right: 12vw;

    .text {
      font-size: rem(22);
    }
  }
}

.ucp-triangle-know-more-large {
  @include svg-standalone(206, theme-color(green), normal, true);
  color: theme-color(white);
  cursor: pointer;
  display: none;
  font-size: rem(30);
  left: 10vw;
  padding: rem(70) rem(20) 0 rem(30);
  position: absolute;
  top: 40vh;
  transition: transform .8s ease-in-out;

  &:hover {
    transform: translateX(#{rem(10)});
  }

  .text {
    display: block;
    line-height: 1;
  }

  .text--bold {
    font-weight: 500;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.ucp-bottom-bar {
  display: none;

  @include media-breakpoint-up(md) {
    background-color: rgba(229, 229, 229, 0.4);
    bottom: 0;
    display: block;
    height: rem(60);
    position: fixed;
    width: 100%;
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }

  100% {
    margin-left: rem(-10);
    opacity: 1;
  }
}

.ucp-intro::before,
.ucp-triangle-name,
.ucp-triangle-start,
.ucp-triangle-intro,
.ucp-scroll-discover,
.ucp-triangle-green-one,
.ucp-triangle-green-two,
.ucp-triangle-red-one,
.ucp-triangle-red-two,
.ucp-triangle-white,
.ucp-triangle-yellow,
.ucp-triangle-know-more-large,
.ucp-start-date,
.ucp-triangle-apply,
.ucp-triangle-know-more {
  animation: intro 1s forwards;
  opacity: 0;
  margin-left: 0;
}

.ucp-intro::before {
  animation-delay: 0s;
}

.ucp-triangle-name {
  animation-delay: 0s;
}

.ucp-triangle-start {
  animation-delay: 1s;
}

.ucp-triangle-intro {
  animation-delay: .6s;
}

.ucp-scroll-discover {
  animation-delay: .8s;
}

.ucp-triangle-green-one {
  animation-delay: 1s;
}

.ucp-triangle-green-two {
  animation-delay: 1.2s;
}

.ucp-triangle-red-one {
  animation-delay: 1s;
}

.ucp-triangle-red-two {
  animation-delay: 1.2s;
}

.ucp-triangle-white {
  animation-delay: 1.3s;
}

.ucp-triangle-yellow {
  animation-delay: 1.2s;
}

.ucp-triangle-know-more-large {
  animation-delay: 1.2s;
}

.ucp-start-date {
  animation-delay: 1.4s;
}

.ucp-triangle-apply {
  animation-delay: .6s;
}

.ucp-triangle-know-more {
  animation-delay: 1s;
}
</style>
