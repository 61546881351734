<template>
  <div class="ucp-calendar">
    <div class="container calendar-container">
      <h1 class="content-title">{{ data.container_title }}</h1>

      <div class="row">
        <div class="col">
          <multiselect
              v-model="choice"
              :options="getCities(calendarSchools)"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :placeholder="data.select_text"
              open-direction="bottom"
          ></multiselect>
        </div>
      </div>

      <div class="row school-container" v-for="school in getFilteredSchools(calendarSchools)">
        <div class="col">
          <div class="row">
            <div class="col-5 school__name">{{ school.name }}</div>

            <div class="col school-phase" v-if="school.notReady">&nbsp;</div>

            <div class="col school-phase" v-else>
              <span v-if="school.dates.length === 1">Fase Única</span>
              <span v-else>{{ school.phases && school.phases[0] ? school.phases[0] : '1.ª Fase' }}</span>
            </div>

            <div class="col school-phase" v-if="school.dates.length >= 2">
              <span>{{ school.phases && school.phases[1] ? school.phases[1] : '2.ª Fase' }}</span>
            </div>

            <div class="col school-phase" v-if="choiceTotalPhases === 3 || school.dates.length >= 3">
              <span v-if="school.dates.length >= 3">{{ school.phases && school.phases[2] ? school.phases[2] : '3.ª Fase' }}</span>
              <span v-else>&nbsp;</span>
            </div>
          </div>

          <div class="row school__course" v-for="course in school.courses">
            <div class="col-5 school__course__name">{{ course.name }}</div>

            <div class="col school__course__date" v-if="school.notReady">{{ school.dates[0] }}</div>
            <div class="col school__course__date" v-else v-for="date in school.dates">{{ date }}</div>

            <div class="col school__course__date" v-if="choiceTotalPhases === 3 && school.dates.length !== 3">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>

    <footer class="ucp-footer">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 text-center text-md-left">
            <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-footer__logo"/>
          </div>
          <div class="col-12 col-md-6 text-center text-md-right ucp-footer__address">
            Universidade Católica Portuguesa<br/>Palma de Cima<br/>1469-023 Lisboa<br/>Portugal
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 text-center text-md-left d-none d-md-block">
            <span class="ucp-footer__copyright">© {{ new Date().getFullYear() }} Universidade Católica Portuguesa</span>
          </div>
          <div class="col-12 col-md-6 text-center text-md-right">
            <a href="https://www.ucp.pt/" class="ucp-footer__social-link">website</a>
            <a href="https://www.facebook.com/universidadecatolicaportuguesaoficial/" class="ucp-footer__social-link">facebook</a>
            <a href="https://www.instagram.com/universidadecatolicaportuguesa/" class="ucp-footer__social-link">instagram</a>
            <a href="https://www.youtube.com/channel/UC_C0pjNliIPzumLvui7xMhQ"
               class="ucp-footer__social-link">youtube</a>
          </div>
          <div class="col-12 col-md-6 text-center text-md-left d-md-none">
            <span class="ucp-footer__copyright">© {{ new Date().getFullYear() }} Universidade Católica Portuguesa</span>
          </div>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
import { isMobileOnly, deviceDetect } from 'mobile-device-detect'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    callback: Function,
    formCallback: Function,
    mobileCallback: Function
  },
  data () {
    return {
      choice: undefined,
      choiceTotalPhases: 2,
      citiesBlacklist: [/*'Lisboa', 'Viseu', 'Plurilocalização'*/],
      calendarSchools: {},
      data: {},
      isMobileOnly: isMobileOnly,
      containerId: '',
      value: '',
      options: ['Select option', 'options', 'selected', 'mulitple', 'label', 'searchable', 'clearOnSelect', 'hideSelected', 'maxHeight', 'allowEmpty', 'showLabels', 'onChange', 'touched']
    }
  },
  methods: {
    loadData: function () {
      import(`@/data/calendarSchools.json`).then((data) => {
        // "Evan You" Solution https://github.com/vuejs/Discussion/issues/292#issuecomment-122825813
        this.calendarSchools = JSON.parse(JSON.stringify(data.default))
      })

      import(`@/data/strings/introduction.json`).then((data) => {
        this.data = data.default
      })

      return this
    },
    nextSection (e) {
      e.preventDefault()

      if (!this.isMobileOnly) {
        this.callback()
      }
    },
    getCities: function (schools) {
      // To avoid exception on first access, because schools is an Observer array
      if (typeof schools.filter !== 'function') return []

      let results = [...new Set(schools.map((school) => {
        // if (this.citiesBlacklist.includes(school.city)) return ''
        return school.city
      }))]

      return results.filter((city) => city !== '')
    },
    getFilteredSchools: function (schools) {
      // To avoid exception on first access, because schools is an Observer array
      if (typeof schools.filter !== 'function') return schools

      return schools.filter((school) => {
        if (this.choice !== '' && school.city === this.choice) {
          if (school.dates.length > this.choiceTotalPhases) {
            this.choiceTotalPhases = school.dates.length
          }

          return true
        }

        return false
      })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    choice() {
      // Reset total of phases
      this.choiceTotalPhases = 2
    }
  }
}
</script>

<style lang="scss" scoped>

@import "~vue-multiselect/dist/vue-multiselect.min.css";

.ucp-calendar {
  background-color: theme-color(gray-lighter);
  min-height: 100vh;
  padding-bottom: rem(430);
  position: relative;

  @include media-breakpoint-up(md) {
    overflow: auto;
    padding-bottom: rem(260);
  }
}

.ucp-triangle-name {
  display: inline-block;
  left: rem(-104);
  line-height: rem(60);
  position: absolute;
  padding-right: rem(70);
  top: rem(300);
  transform: rotate(-90deg);
  z-index: 1;

  span {
    font-weight: 700;
  }

  @include media-breakpoint-up(md) {
    bottom: rem(270);
    left: rem(80);
    line-height: normal;
    padding-right: 0;
    right: auto;
    top: auto;
    transform: rotate(0);
  }
}

.ucp-triangle-start {
  @include svg-standalone(80, theme-color(yellow), normal, true);
  color: theme-color(blue);
  display: none;
  left: 12vw;
  line-height: 1;
  font-size: rem(12);
  padding: rem(22) 0 0 rem(10);
  position: absolute;
  text-transform: uppercase;
  top: 40vh;
  z-index: 1;

  span {
    display: block;
    font-weight: 400;
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(140, theme-color(yellow), normal, true);
    left: 48vw;
    font-size: rem(20);
    padding: rem(40) rem(20) 0 rem(16);
    top: 10vh;
  }
}

.ucp-triangle-introduction {
  @include svg-standalone(210, theme-color(blue), large, true);
  bottom: 45vh;
  color: theme-color(white);
  left: 50vw;
  padding: rem(44) rem(30) 0 rem(24);
  position: absolute;
  text-transform: uppercase;
  transition: transform .8s ease-in-out;

  .text,
  .large,
  .yellow {
    display: block;
  }

  .text {
    color: theme-color(white);
    font-size: rem(13);
    line-height: 1.2;
    padding-right: rem(120);
  }

  .large {
    font-size: rem(22);
    font-weight: 400;
    line-height: 1;
    padding-right: rem(40);
  }

  .yellow {
    font-size: rem(20);
    color: theme-color(yellow);
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(500, theme-color(blue), large, true);
    left: 52vw;
    padding: rem(100) rem(70) rem(100) rem(50);
    top: 12vh;

    .text {
      font-size: rem(30);
    }

    .large,
    .yellow {
      font-size: rem(60);
    }
  }
}

@keyframes discover {
  0% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(#{rem(10)});
  }
  100% {
    opacity: 0;
  }
}

.ucp-scroll-discover {
  bottom: rem(30);
  color: theme-color(white);
  padding: 0 rem(20) 0 0;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  transition: $transition-base;

  a {
    color: theme-color(white);
  }

  span {
    font-weight: 400;
  }

  &::before {
    @include svg-standalone(14, theme-color(white), outline-white, true);
    animation: discover 2s infinite;
    content: '';
    position: absolute;
    right: rem(60);
    top: rem(30);
    transform: rotate(90deg);

    @include media-breakpoint-up(md) {
      right: 0;
      top: rem(12);
    }
  }

  @include media-breakpoint-up(md) {
    bottom: rem(40);
    color: theme-color(black);
    font-size: rem(22);
    right: rem(100);
    transform: none;

    a {
      color: theme-color(black);
    }

    &::before {
      @include svg-standalone(14, theme-color(black), outline, true);
      transform: none;
    }
  }
}

.ucp-triangle-green-one,
.ucp-triangle-green-two {
  @include svg-standalone(20, theme-color(green), normal, true);
  position: absolute;
}

.ucp-triangle-green-one {
  display: none;
  left: 40vw;
  top: 65vh;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.ucp-triangle-green-two {
  left: 12vw;
  top: 56vh;

  @include media-breakpoint-up(md) {
    left: 77vw;
    top: 10vh;
  }
}

.ucp-triangle-yellow-two,
.ucp-triangle-red-two {
  position: absolute;
}

.ucp-triangle-yellow-two {
  @include svg-standalone(42, theme-color(yellow), normal, true);
  left: 79vw;
  top: 8vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(42, theme-color(yellow), normal, true);
  }
}

.ucp-triangle-red-two {
  @include svg-standalone(32, theme-color(red), normal, true);
  display: none;
  left: 64vw;
  top: 80vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(36, theme-color(red), normal, true);
    left: 70vw;
    top: 46vh;
    width: rem(24);
  }
}

.ucp-triangle-white {
  @include svg-standalone(28, theme-color(white), normal, true);
  left: 16vw;
  position: absolute;
  top: 73vh;

  @include media-breakpoint-up(md) {
    left: 54.5vw;
    position: absolute;
    top: 58vh;
  }
}

.ucp-triangle-yellow-one {
  @include svg-standalone(28, theme-color(red), normal, true);
  left: 85vw;
  position: absolute;
  top: 38vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(28, theme-color(yellow), normal, true);
    left: 54.5vw;
    position: absolute;
    top: 19vh;
  }
}

.ucp-start-date {
  bottom: rem(50);
  color: theme-color(blue);
  font-size: rem(12);
  left: 50%;
  position: absolute;
  right: auto;
  text-align: center;
  transform: translateX(-50%);
  width: auto;

  @include media-breakpoint-up(md) {
    bottom: rem(270);
    color: theme-color(black);
    font-size: rem(14);
    left: auto;
    right: rem(80);
    text-align: left;
    transform: none;
  }
}

.ucp-triangle-apply {
  @include svg-standalone(105, theme-color(red), normal, true);
  bottom: 20vh;
  cursor: pointer;
  padding: rem(37) rem(14) rem(14) rem(14);
  position: absolute;
  right: rem(130);
  text-align: right;
  transform: rotate(180deg);

  .text {
    color: theme-color(white);
    display: block;
    font-size: rem(16);
    line-height: 1;
    transform: rotate(180deg);
  }

  .text-small {
    display: block;
    font-size: 70%;
    padding-left: rem(36);
    padding-top: rem(10);
  }

  .yellow {
    color: theme-color(yellow);
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(150, theme-color(red), normal, true);
    bottom: 16vh;
    padding-top: rem(31);
    right: 24vw;

    .text {
      font-size: rem(22);
    }

    .text-small {
      font-size: rem(11);
    }
  }
}

.ucp-triangle-know-more {
  @include svg-standalone(105, theme-color(blue), normal, true);
  bottom: 20vh;
  cursor: pointer;
  padding: rem(36) rem(14) rem(14) rem(14);
  position: absolute;
  right: rem(20);

  .text {
    color: theme-color(white);
    display: block;
    font-size: rem(16);
    line-height: 1;
  }

  .yellow {
    color: theme-color(yellow);
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(150, theme-color(blue), normal, true);
    bottom: 16vh;
    padding-top: rem(43);
    right: 12vw;

    .text {
      font-size: rem(22);
    }
  }
}

.ucp-triangle-know-more-large {
  @include svg-standalone(206, theme-color(green), normal, true);
  color: theme-color(white);
  cursor: pointer;
  display: none;
  font-size: rem(30);
  left: 10vw;
  padding: rem(70) rem(20) 0 rem(30);
  position: absolute;
  top: 40vh;
  transition: transform .8s ease-in-out;

  &:hover {
    transform: translateX(#{rem(10)});
  }

  .text {
    display: block;
    line-height: 1;
  }

  .text--bold {
    font-weight: 500;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.ucp-menu-introduction {
  align-items: center;
  background-color: rgba(229, 229, 229, 0.4);
  border-top: rem(40) solid rgba(229, 229, 229, 0);
  bottom: 0;
  display: block;
  justify-content: center;
  position: absolute;
  width: 100%;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  .ucp-rectangle-button {
    border-radius: rem(15);
    cursor: pointer;
    display: block;
    margin: rem(15) auto;
    max-width: rem(250);

    @include media-breakpoint-up(md) {
      margin: rem(15) rem(10);
      max-width: none;
      min-width: rem(240);
    }

    .text {
      color: theme-color(white);
      display: block;
      font-size: rem(16);
      font-weight: 400;
      line-height: 2.5;
      text-align: center;

      @include media-breakpoint-up(md) {
        font-size: rem(20);
      }
    }
  }

  .blue {
    background-color: theme-color(blue);
  }

  .red {
    background-color: theme-color(red);
  }

  .green {
    background-color: theme-color(green);
  }
}

.calendar-container {

  @include media-breakpoint-up(md) {
    max-width: 900px;
  }

  .content-title {
    font-size: rem(24);
    font-weight: 500;
    padding: rem(60) rem(20) rem(20) rem(40);
    position: relative;

    @include media-breakpoint-up(md) {
      font-size: rem(35);
      margin-bottom: rem(24);
      padding: rem(60) rem(20) rem(20) rem(60);
    }

    &::before {
      @include svg-standalone(12, theme-color(blue), normal, true);
      content: '';
      display: block;
      height: rem(26);
      left: 0;
      position: absolute;
      width: rem(42);

      @include media-breakpoint-up(md) {
        height: rem(42);
        width: rem(49);
      }
    }
  }

  .school-container {
    background-color: theme-color(white);
    margin: 0 0 rem(20);
    padding: rem(40) 0;

    @include media-breakpoint-up(md) {
      padding: rem(40) rem(20);
    }
  }

  .school__name,
  .school-phase {
    font-size: rem(16);
    font-weight: 400;
    margin-bottom: rem(20);

    @include media-breakpoint-up(md) {
      font-size: rem(22);
    }
  }

  .school__name {
    line-height: 18px;

    @include media-breakpoint-up(md) {
      line-height: 24px;
    }
  }

  .school-phase {
    position: relative;

    span {
      position: absolute;
      bottom: 0;
    }
  }

  .school__course {
    padding-bottom: 20px;
  }

  .school__course__name,
  .school__course__date {
    color: theme-color(gray);
    font-size: rem(14);
    font-weight: 300;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-size: rem(18);
    }
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }

  100% {
    margin-left: rem(-10);
    opacity: 1;
  }
}

.ucp-introduction::before,
.ucp-triangle-name,
.ucp-triangle-start,
.ucp-triangle-introduction,
.ucp-scroll-discover,
.ucp-triangle-green-one,
.ucp-triangle-green-two,
.ucp-triangle-yellow-two,
.ucp-triangle-red-two,
.ucp-triangle-white,
.ucp-triangle-yellow-one,
.ucp-triangle-know-more-large,
.ucp-start-date,
.ucp-triangle-apply,
.ucp-triangle-know-more {
  animation: intro 2s forwards;
  opacity: 0;
  margin-left: 0;
}

.ucp-introduction::before {
  animation-delay: .5s;
}

.ucp-triangle-name {
  animation-delay: .5s;
}

.ucp-triangle-start {
  animation-delay: 1.4s;
}

.ucp-triangle-introduction {
  animation-delay: .6s;
}

.ucp-scroll-discover {
  animation-delay: .8s;
}

.ucp-triangle-green-one {
  animation-delay: 1s;
}

.ucp-triangle-green-two {
  animation-delay: 1.2s;
}

.ucp-triangle-yellow-two {
  animation-delay: 1.4s;
}

.ucp-triangle-red-two {
  animation-delay: 1.6s;
}

.ucp-triangle-white {
  animation-delay: 1.8s;
}

.ucp-triangle-yellow-one {
  animation-delay: 2s;
}

.ucp-triangle-know-more-large {
  animation-delay: 1.2s;
}

.ucp-start-date {
  animation-delay: 1.4s;
}

.ucp-triangle-apply {
  animation-delay: .6s;
}

.ucp-triangle-know-more {
  animation-delay: 1s;
}

.ucp-footer {
  background-color: theme-color(white);
  bottom: 0;
  padding: rem(65) 0 rem(20);
  position: absolute;
  width: 100%;
}

.ucp-footer__logo {
  filter: brightness(0);
  margin-bottom: rem(40);
  max-width: rem(220);

  @include media-breakpoint-up(md) {
    max-width: rem(250);
  }
}

.ucp-footer__address {
  line-height: 20px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    line-height: 24px;
  }
}

.ucp-footer__copyright {
  display: block;
  font-size: rem(13);
  margin: rem(20) 0;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

.ucp-footer__social-link {
  color: theme-color(black);
  display: inline-block;
  font-size: rem(14);
  font-weight: 600;

  &:not(:first-child) {
    margin-left: rem(20);
  }

  @include media-breakpoint-up(md) {
    margin-left: rem(40);
  }
}

</style>

<style lang="scss">

// Multiselect
// --------------------------------------------------------------------------

.multiselect {
  border-bottom: rem(1) solid #979797;
  margin-bottom: rem(40);
  max-width: rem(440);
  min-height: rem(50);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(100);
    margin-left: rem(60);
  }
}

.multiselect__select:before {
  background: transparent url('~@/img/triangle.svg') no-repeat center center / contain;
  border: 0;
  display: block;
  height: rem(14);
  top: rem(16);
  transform: rotate(90deg);
  width: rem(14);
}

.multiselect--active .multiselect__select {
  transform: none;
}

.multiselect__input,
.multiselect__single {
  background-color: transparent;
}

.multiselect__tags {
  background-color: transparent;
  border: 0;
  padding-left: 0;
}

.multiselect__placeholder,
.multiselect__single {
  color: theme-color(black);
  display: block !important;
  font-size: rem(16);
  font-weight: normal;

  @include media-breakpoint-up(md) {
    font-size: rem(25);
  }
}

.multiselect__placeholder {
  font-weight: 400;
}

.multiselect__content-wrapper {
  border-radius: 0;
  box-shadow: 0 rem(8) rem(14) rgba(black, .25);
}

.multiselect__option {
  border-bottom: rem(1) solid rgba(151, 151, 151, .2);
  color: theme-color(black);
  font-size: rem(12);
  font-family: $font-family-monospace;
  min-height: rem(60);
  padding-bottom: rem(20);
  padding-left: rem(40);
  padding-top: rem(20);

  @include media-breakpoint-up(md) {
    font-size: rem(16);
  }
}

.multiselect__option--selected {
  background-color: rgba(black, .1);
  color: theme-color(black);
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: theme-color(white);
  color: theme-color(black);
}

.multiselect__option--highlight {
  background-color: theme-color(white);
}

.multiselect__option--selected {
  background-color: theme-color(white);
  color: theme-color(black);
  position: relative;
  font-weight: $font-weight-base;

  &::before {
    @include svg-standalone(15, theme-color(blue), normal, true);
    content: '';
    display: block;
    height: rem(30);
    left: rem(15);
    position: absolute;
    top: rem(15);
  }
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: theme-color(white);
  color: theme-color(blue);
  font-weight: $font-weight-base;
}

.multiselect__option--highlight {
  background-color: theme-color(white);
  position: relative;
  color: theme-color(blue);

  &::before {
    @include svg-standalone(15, theme-color(blue), normal, true);
    content: '';
    display: block;
    height: rem(30);
    left: rem(15);
    position: absolute;
    top: rem(15);
  }
}

</style>
