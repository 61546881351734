import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLocalStorage from 'vue-localstorage'
import Vuelidate from 'vuelidate'
import VueResource from "vue-resource"
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueScrollStop from 'vue-scroll-stop'
import smoothscroll from 'smoothscroll-polyfill'
import browserDetect from 'vue-browser-detect-plugin'
import VueTagManager from "vue-tag-manager"
import VueGtag from "vue-gtag"


window.$ = require('jquery');
window.JQuery = require('jquery');

Vue.config.productionTip = false;

Vue.use(VueLocalStorage);
Vue.use(Vuelidate);
Vue.use(VueResource);
Vue.use(PerfectScrollbar);
Vue.use(VueScrollStop);
Vue.use(browserDetect);

Vue.use(VueTagManager, {
  gtmId: 'GTM-W5N4PT9'
});

Vue.use(VueGtag, {
  config: { id: "UA-170960014-1" }
});

new Vue({
  localStorage: {},
  methods: {},
  watch: {},
  router,
  render: function (h) { return h(App) }
}).$mount('#app');

// kick off the polyfill!
smoothscroll.polyfill();
