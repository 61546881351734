<template>
  <div class="container-fluid ucp-form-navigation">
    <div class="row">
      <div class="col-6 col-md-3 order-2 order-md-1">
        <button class="btn ucp-btn ucp-btn--prev" v-if="showPrevious" @click="callback(step - 1)"><span class="ucp-btn__icon"></span> {{data.previous}}</button>
      </div>
      <div class="col-12 col-md-6 order-1 order-md-2 text-center ucp-form-navigation__label">
        <div v-if="showLabel && step === 1">{{ data.select_areas }}</div>
        <div v-if="showLabel && step === 2">{{ data.select_courses }}</div>
        <div v-if="showLabel && step >= 3">{{ data.select_places }}</div>
      </div>
      <div class="col-6 col-md-3 order-3 order-md-3 text-right text-md-left">
        <button class="btn ucp-btn ucp-btn--next" v-if="showNext" :disabled="disableNext" @click="callback(step + 1)">{{data.next}} <span class="ucp-btn__icon"></span></button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      step: Number,
      showLabel: {
        type: Boolean,
        default: true
      },
      showPrevious: Boolean,
      showNext: Boolean,
      disableNext: Boolean,
      stepBegin: Number,
      stepLast: Number,
      callback: Function,
    },
    data() {
      return {
        data: {},
      }
    },
    methods: {
      loadData() {
        import(`@/data/strings/form.json`).then((data) => {
          this.data = data.default;
        });

        return this;
      },
    },
    created() {
      this.loadData();
    },
  }
</script>

<style lang="scss" scoped>

  .ucp-form-navigation {
    @include media-breakpoint-up(md) {
      bottom: 0;
      left: 0;
      max-width: 100vw;
      padding-bottom: rem(20);
      position: absolute;
      width: 100vw;
    }
  }

  .ucp-btn {
    position: relative;
    text-transform: lowercase;
    transition: $transition-base;

    @include media-breakpoint-up(md) {
      margin: 0 rem(80);
    }
  }

  .ucp-btn--prev {
    font-size: rem(16);
    padding-left: rem(30);

    @include media-breakpoint-up(md) {
      font-size: rem(20);
    }

    .ucp-btn__icon {
      @include svg-standalone(14, theme-color(black), outline, true);
      left: 0;
      position: absolute;
      transform: rotate(180deg);
      top: rem(13);
    }
  }

  .ucp-btn--next {
    font-size: rem(16);
    padding-right: rem(30);

    @include media-breakpoint-up(md) {
      font-size: rem(20);
    }

    .ucp-btn__icon {
      @include svg-standalone(14, theme-color(black), outline, true);
      position: absolute;
      right: 0;
      top: rem(13);
    }
  }

  .ucp-form-navigation__label {
    color: rgba(theme-color(gray), .7);
    font-size: rem(16);
    padding-top: rem(6);

    @include media-breakpoint-up(md) {
      font-size: rem(20);
    }
  }
</style>
