<template>
  <div id="scroll-container" class="ucp-home" :class="{'ucp-home--leaving': delayedStepLinks > 1}">
    <div class="ucp-sections-container">
      <ucp-section :class="{'ucp-section--md-leaving': delayedStep > 1 && delayedStep <= 2}" v-if="step === 1">
        <ucp-intro :callback="move" :form-callback="move" :mobile-callback="linksPage"></ucp-intro>
      </ucp-section>

      <ucp-section v-if="step === 2 || isMobileOnly">
        <ucp-messages :form-callback="formPage" :callback="homePage" :mobile-callback="linksPage" :calendar-callback="calendarSection" />
      </ucp-section>

<!--      <ucp-section v-if="step === 3" class="d-none d-md-block">-->
<!--        <ucp-introduction :callback="move" :form-callback="formPage" :mobile-callback="linksPage" />-->
<!--      </ucp-section>-->

<!--      <ucp-section v-if="isMobileOnly" ref="calendar">-->
<!--        <ucp-calendar :callback="move" :form-callback="formPage" :mobile-callback="linksPage" />-->
<!--      </ucp-section>-->
    </div>
  </div>
</template>

<script>
  import ucpSection from "../components/Section";
  import ucpIntro from "../components/Intro";
  import ucpIntroduction from "../components/Introduction";
  import ucpCalendar from "../components/Calendar";
  import ucpMessages from "../components/Messages";
  import ucpForm from "../components/Form";
  import { isMobileOnly, deviceDetect } from 'mobile-device-detect';

  export default {
    name: 'intro',
    data() {
      return {
        delayedStep: 1,
        delayedStepLinks: 1,
        step: 1,
        isMobileOnly: isMobileOnly,
        scrollIns: null
      };
    },
    created() {},
    methods: {
      move() {
        this.delayedStep++;
        setTimeout(() => this.step++, 200);
      },
      homePage() {
        this.delayedStepLinks++;
        setTimeout(() => this.$router.push({ name: 'home' }), 1000);
      },
      linksPage() {
        this.delayedStepLinks++;
        setTimeout(() => this.$router.push({ name: 'links' }), 1000);
      },
      formPage() {
        this.delayedStepLinks++;
        setTimeout(() => this.$router.push({ name: 'apply' }), 1000);
      },
      calendarSection() {
        window.scroll({
          top: this.$refs.calendar.$el.offsetTop,
          behavior: 'smooth'
        });
      }
    },
    components: {
      ucpForm,
      ucpMessages,
      ucpIntro,
      ucpIntroduction,
      ucpCalendar,
      ucpSection,
    }
  }
</script>

<style lang="scss" scoped>
  .ucp-home {
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: absolute;
    width: 100vw;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      height: 100vw;
      overflow: hidden;
      transform: rotate(270deg) translateX(-100%);
      transform-origin: top left;
      width: 100vh;
    }
  }

  .ucp-sections-container {
    @include media-breakpoint-up(md) {
      display: inline-flex;
      transform: rotate(90deg) translateY(-100vh);
      transform-origin: top left;
    }
  }

  .ucp-form {
    @include media-breakpoint-up(md) {
      display: inline-flex;
      position: relative;
    }
  }

  @keyframes intro {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes introLinks {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .ucp-home--leaving {
    animation: introLinks 1s;
    opacity: 0;
  }

  .ucp-section {
    transition: $transition-fade;

    @include media-breakpoint-up(md) {
      animation: intro 2s;

      &.ucp-section--md-leaving {
        opacity: 0;
      }
    }
  }
</style>
