<template>
  <div id="app">
    <b-alert v-if="$browserDetect.isEdge || $browserDetect.isFirefox" class="browser-update-advice"
      :show="!browserUpdateAlertDismissed" fade variant="warning">
      {{ data.browser_update_advice_text }}
      <button type="button" class="btn btn-link" @click="browserUpdateAlertDismissed = true">
        {{ data.browser_update_advice_dismiss_text }}
      </button>
    </b-alert>
    <header class="ucp-header" :class="{
      'ie-header': $browserDetect.isIE,
    }">
      <router-link :to="{ name: 'home' }">
        <img ref="sitelogo" src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-header__logo" />
      </router-link>
    </header>
    <router-view v-if="!$browserDetect.isIE" />
    <div v-else class="alert alert-warning ie-message" role="alert">
      <h2>
        <span class="material-icons">error</span>
        {{ data.ie_unsupported_text }}
      </h2>
    </div>
  </div>
</template>

<script>
import { BAlert, AlertPlugin } from "bootstrap-vue";

export default {

  components: {
    BAlert,
    AlertPlugin,
  },
  data() {
    return {
      data: {},
      browserUpdateAlertDismissed: false,
    };
  },
  methods: {
    loadData() {
      import(`@/data/strings/header.json`).then((data) => {
        this.data = data.default;
      });

      return this;
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    let isChromium = window.chrome;
    let winNav = window.navigator;
    let vendorName = winNav.vendor;
    let isOpera = typeof window.opr !== "undefined";
    let isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    let isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
      // is Google Chrome on IOS
      document.getElementById('app').classList.add('chrome-browser')
    } else if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      // is Google Chrome
      console.log('is Google Chrome')
      document.getElementById('app').classList.add('chrome-browser')
    } else {
      // not Google Chrome
    }
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!!isSafari) {
      document.getElementById('app').classList.add('safari-browser')
    }
  },
};
</script>

<style lang="scss">
@import "~@/scss/main.scss";

.ucp-header {
  background-color: transparent;
  left: 0;
  padding: rem(40) 0 0 rem(20);
  position: absolute;
  top: 0;
  width: auto;
  z-index: 1;

  @include media-breakpoint-up(md) {
    padding: rem(30) rem(40);
  }
}

.ucp-header__logo {
  filter: brightness(0);
  transition: opacity 300ms;
  width: rem(160);

  @include media-breakpoint-up(md) {
    width: rem(264);
  }
}

.browser-update-advice {
  display: none;
  padding-top: 0;
  padding-bottom: 0;
  position: absolute !important;
  margin-bottom: 0 !important;
  top: 0;
  width: 100%;
  z-index: 1;

  @include media-breakpoint-up(md) {
    display: block;
  }

  .btn {
    font-size: rem(16);
    padding: 0 rem(5);
  }
}

.browser-update-advice+.ucp-header {
  @include media-breakpoint-up(md) {
    padding-top: rem(64);
  }
}

.ie-header {
  position: relative;
}

.ie-message {
  width: 100%;
  padding: rem(50);

  h2 {
    color: #856404;
    text-align: center;
  }
}

.chrome-browser,
.safari-browser {
  .text-hilighted {
    padding-top: rem(6) !important;
  }
}</style>
