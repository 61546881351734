<template>
  <div id="scroll-container" class="ucp-home" :class="{ 'ucp-home--leaving': delayedStepLinks > 1 }">
    <div class="ucp-sections-container">
      <ucp-section>
        <ucp-introduction :callback="move" :form-callback="formPage" :other-callback="linksOther"
          :mobile-callback="linksPage" />
      </ucp-section>
    </div>
  </div>
</template>

<script>
import ucpSection from "../components/Section";
import ucpIntro from "../components/Intro";
import ucpIntroduction from "../components/Introduction";
import ucpCalendar from "../components/Calendar";
import ucpMessages from "../components/Messages";
import ucpForm from "../components/Form";
import { isMobileOnly, deviceDetect } from 'mobile-device-detect';

export default {
  name: 'home',
  mounted() {
    const isMobile = window.innerWidth > 768 ? false : true;
    const thisSiteLogo = document.querySelector('.ucp-header__logo');
    if (isMobile) {
      window.addEventListener("DOMContentLoaded", (event) => {
        let ucpIntro = document.querySelector(".ucp-introduction")
        let ucpIntroductionImgBanner = document.querySelector(".ucp-introduction-img-banner")


        ucpIntro.addEventListener("scroll", function (e) {
          ucpIntroductionImgBanner.getBoundingClientRect().top < -30
            ? (thisSiteLogo.style.opacity = "0")
            : (thisSiteLogo.style.opacity = "100");
        });
        window.addEventListener("scroll", function (e) {

          window.scrollY > 100
            ? (thisSiteLogo.style.opacity = "0")
            : (thisSiteLogo.style.opacity = "100");
        });
      });
    }
  },
  data() {
    return {
      delayedStep: 1,
      delayedStepLinks: 1,
      step: 1,
      isMobileOnly: isMobileOnly,
      scrollIns: null
    };
  },
  created() {
  },
  methods: {
    move() {
      this.delayedStep++;
      setTimeout(() => this.step++, 200);
    },
    linksPage() {
      this.delayedStepLinks++;
      setTimeout(() => this.$router.push({ name: 'links' }), 1000);
    },
    linksOther() {
      this.delayedStepLinks++;
      setTimeout(() => this.$router.push({ name: 'linksother' }), 1000);
    },

    formPage() {
      this.delayedStepLinks++;
      setTimeout(() => this.$router.push({ name: 'apply' }), 1000);
    },
    calendarSection() {
      window.scroll({
        top: this.$refs.calendar.$el.offsetTop,
        behavior: 'smooth'
      });
    }
  },
  components: {
    ucpForm,
    ucpMessages,
    ucpIntro,
    ucpIntroduction,
    ucpCalendar,
    ucpSection,
  }
}
</script>

<style lang="scss" scoped>
.ucp-home {
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: absolute;
  width: 100vw;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-up(md) {
    height: 100vw;
    overflow: hidden;
    transform: rotate(270deg) translateX(-100%);
    transform-origin: top left;
    width: 100vh;
  }
  /* legacy from when buttons slided downwards instead of upwards */
  /* @media (min-width: 992px) and (max-height: 801px) {
    width: 120vh;
  } */
}

.ucp-sections-container {
  @include media-breakpoint-up(md) {
    display: inline-flex;
    transform: rotate(90deg) translateY(-100vh);
    transform-origin: top left;
  }
  /* legacy from when buttons slided downwards instead of upwards */
  /* @media (min-width: 992px) and (max-height: 801px) {
    transform: rotate(90deg) translateY(-120vh);
  } */
}

.ucp-form {
  @include media-breakpoint-up(md) {
    display: inline-flex;
    position: relative;
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes introLinks {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ucp-home--leaving {
  animation: introLinks 1s;
  opacity: 0;
}

.ucp-section {
  transition: $transition-fade;

  @include media-breakpoint-up(md) {
    animation: intro 2s;

    &.ucp-section--md-leaving {
      opacity: 0;
    }
  }
}
</style>
