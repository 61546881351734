<template>
  <div id="scroll-container" class="ucp-home">
    <header class="ucp-header d-md-none">
      <router-link :to="{ name: 'home' }">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-header__logo" />
      </router-link>
    </header>

    <div class="ucp-sections-container">
      <ucp-form class="ucp-form"/>
    </div>
  </div>
</template>

<script>
  import ucpForm from "../components/Form";

  export default {
    name: 'apply',
    data() {
      return {};
    },
    mounted() {
      // Clear header raw styles
      let header = this.$root.$el.getElementsByClassName('ucp-header')[0];
      if (header.hasAttribute('style')) header.removeAttribute('style');
    },
    methods: {
    },
    components: {
      ucpForm,
    }
  }
</script>

<style lang="scss" scoped>
  .ucp-home {
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: absolute;
    width: 100vw;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      height: 100vw;
      overflow: hidden;
      transform: rotate(270deg) translateX(-100%);
      transform-origin: top left;
      width: 100vh;
    }
  }

  .ucp-sections-container {
    @include media-breakpoint-up(md) {
      display: inline-flex;
      transform: rotate(90deg) translateY(-100vh);
      transform-origin: top left;
    }
  }

  .ucp-form {
    @include media-breakpoint-up(md) {
      display: inline-flex;
      position: relative;
    }
  }

  @keyframes intro {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes introLinks {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .ucp-home--leaving {
    animation: introLinks 1s;
    opacity: 0;
  }

  .ucp-section {
    transition: $transition-fade;

    @include media-breakpoint-up(md) {
      animation: intro 2s;

      &.ucp-section--md-leaving {
        opacity: 0;
      }
    }
  }
</style>
