import Vue from 'vue'
import Router from 'vue-router'
import Intro from './views/Intro.vue'
import Home from './views/Home.vue'
import Form from './views/Form.vue'
import Links from './views/Links.vue'
import Thanks from './views/Thanks.vue'
import Linkslisbon from'./views/Linkslisbon.vue'
import LinksOther from './views/Linksother.vue'
import LinkslisbonOther from './views/LinkslisbonOther.vue'
import { isMobileOnly, deviceDetect } from 'mobile-device-detect'

Vue.use(Router);

function init() {
  let metaData = {
    title: 'Candidaturas | UCP',
    metaTags: [
      {
        name: 'description',
        content: 'Página criada com o propósito de informar os alunos do ensino secundário, prestes a entrar no ensino universitário, sobre os cursos de Licenciatura e Mestrados Integrados lecionados na Universidade Católica Portuguesa e sobre as candidaturas aos respetivos programas.'
      },
      {
        property: 'og:description',
        content: 'Página criada com o propósito de informar os alunos do ensino secundário, prestes a entrar no ensino universitário, sobre os cursos de Licenciatura e Mestrados Integrados lecionados na Universidade Católica Portuguesa e sobre as candidaturas aos respetivos programas.'
      },
      {
        property: 'keywords',
        content: 'UCP, Universidade Católica Portuguesa, Faculdade, Universidade, Ensino, Licenciatura, Cursos, Lisboa, Porto, Braga, Viseu, Línguas e Humanidades, Economia, Artes, Ciências,  Gestão, Mestrados Integrados, Ciências Sociais, Saúde, Tecnologias, Direito'
      }
    ]
  };

  let router  = new Router(
    {
      mode: 'history',
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
      routes: [
        {
          path: '/',
          name: 'home',
          component: Home,
          meta: metaData
        },
        {
          path: '/home',
          name: 'home',
          meta: metaData,
          component: Home
        },
        {
          path: '/aplicar',
          name: 'apply',
          meta: metaData,
          component: Form
        },
        {
          path: '/locais',
          name: 'links',
          meta: metaData,
          component: Links
        },

        {
          path: '/candidatura-lisboa',
          name: 'linkslisbon',
          meta: metaData,
          component: Linkslisbon
        },

        {
          path: '/candidatura-lisboa-outros',
          name: 'linkslisbon',
          meta: metaData,
          component: LinkslisbonOther
        },

        {
          path: '/candidatura-outro',
          name: 'linksother',
          meta: metaData,
          component: LinksOther
        },
        {
          path: '/submetido',
          name: 'thanks',
          meta: metaData,
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
          component: Thanks
        }
      ]
    }
  );

  router.beforeEach(function (to, from, next) {
    if (to.name === 'intro' && isMobileOnly) {
      next({name: 'home'});
    }

    // Clear hash from to url on apply router, if is a direct access to a step
    if (to.name === 'apply' && to.hash !== '' && !from.name) {
      next({name: to.name, hash: ''});
    }

    if (!to.meta) next();

    // If a route with a title was found, set the document (page) title to that value.
    if(to.meta.title) document.title = to.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Turn the meta tag definitions into actual elements in the head.
    to.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      document.head.appendChild(tag)
      Vue.nextTick(() => {
        document.title = to.meta.title
      });
    });

    next();
  });

  return router;
}

export default init();
