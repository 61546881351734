import { render, staticRenderFns } from "./LinkslisbonOther.vue?vue&type=template&id=0b55c223&scoped=true&"
import script from "./LinkslisbonOther.vue?vue&type=script&lang=js&"
export * from "./LinkslisbonOther.vue?vue&type=script&lang=js&"
import style0 from "./LinkslisbonOther.vue?vue&type=style&index=0&id=0b55c223&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b55c223",
  null
  
)

export default component.exports