<template>
  <div class="ucp-links">
    <header class="ucp-header d-md-none">
      <router-link :to="{ name: 'home' }">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-header__logo" />
      </router-link>
    </header>
    <div class="container-fluid applications-wrapper">
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1">
          <h2 class="ucp-title">{{ data.question }}</h2>
          <h3 class="ucp-sub-title">{{ data.sub_question }}</h3>

          <div class="row" v-if="step !== ''">
            <div class="col mb-2">
              <a @click="step = ''" class="ucp-btn--prev"><span class="ucp-btn__icon"></span> {{ data.previous }}</a>
            </div>
          </div>
          <div class="row ucp-links-step" v-if="step === ''">
            <div class="col-4">
              <!--              <a @click.prevent="step = data.city1" class="ucp-link">{{ data.city1 }}</a>-->
              <a :href="lisbonUrl" class="ucp-link">{{ data.city1 }}</a>
            </div>
            <!--<div class="col-6">
              <a :href="data.city2_url" target="_blank" class="ucp-link">{{ data.city2 }}</a>
            </div>
            <div class="col-4">
              <a :href="data.city3_url" target="_blank" class="ucp-link">{{ data.city3 }}</a>
            </div>
            <div class="col-4">
              <a :href="data.city4_url" target="_blank" class="ucp-link">{{ data.city4 }}</a>
            </div>-->
          </div>

          <!-- Lisboa -->
          <!--          <div class="row ucp-links-step" v-if="step === data.city1">-->
          <!--            <div class="col-4">-->
          <!--              <a :href="data.city1_1_url" target="_blank" class="ucp-link">{{ data.city1_1 }}</a>-->
          <!--            </div>-->
          <!--            <div class="col-6">-->
          <!--              <a :href="data.city1_2_url" target="_blank" class="ucp-link">{{ data.city1_2 }}</a>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <div class="ucp-footer col-12 d-none d-md-block">
      <div class="ucp-footer__content">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-footer__logo" />
        <address class="ucp-footer__address">
          Universidade Católica Portuguesa<br />
          Palma de Cima<br />
          1649-023 Lisboa<br />
          Portugal
        </address>

        <a href="https://www.ucp.pt/" class="ucp-footer__social-link">website</a>
        <a href="https://www.facebook.com/universidadecatolicaportuguesaoficial/"
          class="ucp-footer__social-link">facebook</a>
        <a href="https://www.instagram.com/universidadecatolicaportuguesa/" class="ucp-footer__social-link">instagram</a>
        <a href="https://www.linkedin.com/school/universidade-cat%C3%B3lica-portuguesa"
          class="ucp-footer__social-link">linkedIn</a>
        <a href="https://www.youtube.com/channel/UC_C0pjNliIPzumLvui7xMhQ" class="ucp-footer__social-link">youtube</a>

        <span class="ucp-footer__copyright">© {{ new Date().getFullYear() }} Universidade Católica
          Portuguesa</span>
      </div>
    </div>

    <div class="ucp-footer col-12 d-md-none">
      <div class="ucp-footer__content">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-footer__logo" />
        <address class="ucp-footer__address">
          Universidade Católica Portuguesa<br />
          Palma de Cima<br />
          1649-023 Lisboa<br />
          Portugal
        </address>

        <div class="ucp-footer__social-links">
          <a href="https://www.ucp.pt/" class="ucp-footer__social-link">website</a>
          <a href="https://www.facebook.com/universidadecatolicaportuguesaoficial/"
            class="ucp-footer__social-link">facebook</a>
          <a href="https://www.instagram.com/universidadecatolicaportuguesa/"
            class="ucp-footer__social-link">instagram</a>
          <a href="https://www.linkedin.com/school/universidade-cat%C3%B3lica-portuguesa"
            class="ucp-footer__social-link">linkedIn</a>
          <a href="https://www.youtube.com/channel/UC_C0pjNliIPzumLvui7xMhQ" class="ucp-footer__social-link">youtube</a>
        </div>

        <span class="ucp-footer__copyright">© {{ new Date().getFullYear() }} Universidade Católica
          Portuguesa</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "links",
  components: {},
  data() {
    return {
      step: "",
      data: {},
      lisbonUrl: '',
    };
  },
  methods: {
    loadData: function () {
      import(`@/data/strings/links.json`).then((data) => {
        this.data = data.default;
      });

      return this;
    },

    locationPage() {
      setTimeout(() => this.$router.push({ name: "linkslisbon" }), 1000);
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    // Clear header raw styles
    let header = this.$root.$el.getElementsByClassName("ucp-header")[0];
    if (header.hasAttribute("style")) header.removeAttribute("style");
    this.lisbonUrl = ` ${window.location.protocol}//${window.location.host}/candidatura-lisboa`
  },
};
</script>

<style lang="scss" scoped>
@keyframes intro {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ucp-links {
  animation: intro 2s;
  transition: $transition-base;

  .applications-wrapper {
    padding-top: rem(100);

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: rem(180);
  }
}

.ucp-title {
  line-height: 1.2;
  margin-bottom: rem(10);
  margin-left: rem(15);
  margin-top: rem(40);
  padding-left: rem(20);
  position: relative;

  &::before {
    @include svg-standalone(34, #61bdaf, normal, true);
    content: "";
    left: rem(-14);
    position: absolute;
    top: rem(-6);
    z-index: -1;
  }

  &:after {
    content: ".1";
    font-weight: 400;
    position: absolute;
    left: 0;
  }

  @include media-breakpoint-up(md) {
    font-size: rem(40);
    margin-left: 0;
    position: relative;
    padding-left: rem(30);
  }
}

.ucp-sub-title {
  font-size: rem(18);
  line-height: 1.2;
  margin-bottom: rem(40);
  margin-left: rem(15);
  padding-left: rem(20);
}

.ucp-links-step {
  animation: intro 2s;
  transition: $transition-base;
}

.ucp-link {
  color: theme-color(black);
  cursor: pointer;
  display: inline-block;
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.2;
  margin: rem(10) rem(10);
  padding: 0 rem(20) 0 rem(30);
  position: relative;
  transition: color 0.2s ease-in-out;
  vertical-align: middle;

  &::after {
    @include svg-standalone(20, theme-color(blue), normal, true);
    border-radius: rem(1);
    content: "";
    height: rem(18);
    left: 0;
    opacity: 0;
    position: absolute;
    top: rem(5);
    transition: $transition-base;
    width: rem(18);
  }

  &:hover::after {
    opacity: 1;
  }

  &.ucp-route-link {
    &:hover {
      color: theme-color(blue)
    }
  }

  @include media-breakpoint-up(md) {
    font-size: rem(22);
  }
}

.ucp-footer {
  background-color: theme-color(gray-light);
  margin-top: rem(30);
  padding-top: rem(40);

  @include media-breakpoint-up(md) {
    height: 100vh;
    margin-top: 0;
    padding: rem(180) rem(40) rem(40);
    position: fixed;
    right: 0;
    text-align: right;
    top: 0;
    width: 30vw;
    z-index: 0;
  }
}

.ucp-footer__content {
  margin-top: rem(30);
  text-align: center;
  height: calc(100vh - #{rem(400)});

  @include media-breakpoint-up(md) {
    text-align: right;
    bottom: rem(40);
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{rem(180)});
    justify-content: flex-end;
    position: absolute;
    width: calc(100% - #{rem(80)});
  }
}

.ucp-footer__logo {
  filter: brightness(0);
  margin-bottom: rem(40);
  max-width: 220px;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
}

.ucp-footer__address {
  font-size: rem(16);
  font-weight: 400;
  margin-bottom: rem(60);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(80);
  }
}

.ucp-footer__social-links {
  @include media-breakpoint-up(md) {
    text-align: right;
  }
}

.ucp-footer__social-link {
  color: theme-color(black);
  display: inline-block;
  font-weight: 600;
  margin: 0 5px;

  @include media-breakpoint-up(md) {
    display: block;
    margin: 0;
    font-weight: 400;
  }
}

.ucp-footer__copyright {
  display: block;
  font-size: rem(13);
  padding-top: rem(30);
  padding-bottom: rem(15);

  @include media-breakpoint-up(md) {
    padding-top: rem(60);
    padding-bottom: 0;
  }
}

.ucp-btn--prev {
  cursor: pointer;
  position: relative;
  text-transform: lowercase;
  font-size: rem(16);
  padding-left: rem(30);

  @include media-breakpoint-up(md) {
    font-size: rem(20);
  }

  .ucp-btn__icon {
    @include svg-standalone(14, theme-color(black), outline, true);
    left: 0;
    position: absolute;
    transform: rotate(180deg);
    top: rem(11);
  }
}
</style>
