<template>
  <div class="ucp-introduction" ref="introduction">
    <!--
      <div class="ucp-animation ucp-triangle-start">
      {{data.small_1}}
      <span>{{data.small_2}}</span>
    </div>
    -->

    <!--<div class="ucp-triangle-know-more-large" @click.prevent="callback">
      <span class="text">{{data.more_1}}</span>
      <span class="text text--bold">{{data.more_2}}</span>
    </div>-->

    <div :class="[
      !!isSafari && 'ucp-introduction-img-banner--safari-browser',
      !!isChromeIOs && 'ucp-introduction-img-banner--chrome-ios-browser'
      ]" class="ucp-introduction-img-banner">
      <img class="d-sm-none ucp-animation join-us-mobile" src="../img/join.png" />
      <div class="ucp-animation ucp-triangle-introduction">
        <div class="ucp-animation ucp-triangle-introduction--wrapper">
          <span class="text text-hilighted text-hilighted--first-line">{{ data.big_1 }}</span>
          <span class="text text-hilighted">{{ data.big_1a }}</span>
          <span class="large">{{ data.big_2 }}</span>
          <span class="large">{{ data.big_2a }}</span>
          <span class="large">{{ data.big_3 }}</span>
          <span class="large">{{ data.big_4 }}</span>
          <span class="large">{{ data.big_5 }}</span>
          <span class="large">{{ data.big_6 }}</span>

          <div class="images-wrapper">
            <div class="student-wrapper">
              <img src="../img/student.png" />
            </div>
            <div class="rings-wrapper">
              <img src="../img/rings.png" />
              <div class="d-none d-sm-block join-us-wrapper">
                <img src="../img/join.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="ucp-animation ucp-start-date">
        <span class="d-md-none">{{ data.start_date_mention }}</span>
        <span class="d-none d-md-block">{{ data.start_date }}</span>
      </div> -->
      <div class="ucp-animation ucp-triangle-name">
        <span>{{ data.person_name }}</span>
        <span class="person-position">{{ data.person_position }}</span>
      </div>
    </div>

    <!--<div class="ucp-animation ucp-triangle-apply d-none d-md-block" @click.prevent="callback">
      <span class="text yellow">{{data.apply_2}}</span>
      <span class="text">{{data.apply_1}}</span>
    </div>-->

    <!--<div class="ucp-animation ucp-triangle-apply" @click.prevent="mobileCallback">
      <span class="text text-small d-none d-md-block">{{ data.start_date_mention }}</span>
      <span class="text yellow">{{data.apply_2}}</span>
      <span class="text">{{data.apply_1}}</span>
    </div>-->

    <!--<div class="ucp-animation ucp-triangle-know-more" @click="formCallback">
      <span class="text">{{data.more_1}}</span>
      <span class="text yellow">{{data.more_2}}</span>
    </div>-->

    <!--<div class="ucp-animation ucp-scroll-discover d-none d-md-block">
      <a href="#" @click="nextSection">
        <span>{{data.bottom_1}}</span>
        {{data.bottom_2}}
      </a>
    </div>-->

    <!--<div class="ucp-animation ucp-scroll-discover d-md-none">
      <a href="#" @click="nextSection">
        <span>{{data.bottom_3}}</span>
        {{data.bottom_4}}
      </a>
    </div>-->

    <div class="ucp-animation ucp-menu-introduction">
      <div class="messages-container">
        <ucp-messages :callback="callback"></ucp-messages>
      </div>
      <div class="buttons-wrapper container">
        <div class="row">
          <div class="ucp-rectangle-button--wrapper">
            <div class="ucp-animation ucp-rectangle-button collapsible stone">
              <div class="ucp-rectangle-button--main-title">
                <span class="text">{{ data.button_1 }}</span>
              </div>
              <div class="secondary-text applications-secondary-text">
                <div class="applications-list-container">
                  <div class="line-viseu">
                    <span class="reset-family">{{ data.button_1_span1 }}</span>
                  </div>
                  <div class="line-viseu">
                    <span class="reset-family">{{ data.button_1_span2 }}</span>
                  </div>
                  <div>
                    <span class="font-bold">{{ data.button_1_span9 }}</span>
                    <span>{{ data.button_1_span3 }}</span>
                  </div>
                  <div class="line-viseu">
                    <span class="reset-family">{{ data.button_1_span4 }}</span>
                  </div>
                  <div class="line-viseu">
                    <span class="reset-family">{{ data.button_1_span5 }}</span>
                  </div>
                  <div>
                    <span>{{ data.button_1_span6 }}</span>
                  </div>
                  <div class="line-viseu">
                    <span class="reset-family">{{ data.button_1_span7 }}</span>
                  </div>
                  <div>
                    <span class="font-bold">{{ data.button_1_span9 }}</span>
                    <span>{{ data.button_1_span701 }}</span>
                  </div>
                  <!-- <div class="line-viseu">
                <span class="reset-family">{{ data.button_1_span21 }}</span>
              </div>
               <div class="line-viseu">
                <span class="reset-family">{{ data.button_1_span22 }}</span>
              </div>
              <div>
                <span class="font-bold">{{ data.button_1_span23 }}</span>
                <span>{{ data.button_1_span24 }}</span>
              </div>
              <div>
                <span class="font-bold">{{ data.button_1_span25 }}</span>
                <span>{{ data.button_1_span26 }}</span>
              </div>
              <div class="line-viseu">
                <span class="reset-family">{{ data.button_1_span27 }}</span>
              </div>
              <div>
                <span class="font-bold">{{ data.button_1_span28 }}</span>
                <span>{{ data.button_1_span29 }}</span>
              </div>
              <div>
                <span class="font-bold">{{ data.button_1_span30 }}</span>
                <span>{{ data.button_1_span31 }}</span>
              </div>
              <div class="line-viseu">
                <span class="reset-family">{{ data.button_1_span32 }}</span>
              </div>
              <div>
                <span class="font-bold">{{ data.button_1_span33 }}</span>
                <span>{{ data.button_1_span34 }}</span>
              </div>
              <div>
                <span class="font-bold">{{ data.button_1_span35 }}</span>
                <span>{{ data.button_1_span36 }}</span>
              </div> -->
                </div>
              </div>
            </div>

          </div>
          <div class="ucp-rectangle-button--wrapper">
            <div class="ucp-animation ucp-rectangle-button collapsible ocean">
              <div class="ucp-rectangle-button--main-title">
                <span class="text">{{ data.button_3 }}</span>
                <span class="text">{{ data.button_21 }}</span>
              </div>
              <div class="secondary-text">
                <span class="text">{{ data.button_2_span2 }}</span>
                <button @click.prevent="mobileCallback">candidatar</button>
              </div>
            </div>
          </div>
          <div class="ucp-rectangle-button--wrapper">
            <div class="ucp-animation ucp-rectangle-button collapsible aqua">
              <div class="ucp-rectangle-button--main-title">
                <span class="text">{{ data.button_3 }}</span>
                <span class="text">{{ data.button_31 }}</span>
              </div>
              <div class="secondary-text">
                <span class="text">{{ data.button_3_span1 }}</span>
                <span class="text">{{ data.button_3_span2 }}</span>
                <span class="text">{{ data.button_3_span3 }}</span>
                <span class="text">{{ data.button_3_span4 }}</span>
                <span class="text">{{ data.button_3_span5 }}</span>
                <button @click="otherCallback">candidatar</button>
              </div>
            </div>
          </div>
          <div class="ucp-rectangle-button--wrapper">
            <div class="ucp-animation ucp-rectangle-button blue">
              <div @click="formCallback" class="ucp-rectangle-button--main-title">
                <span class="text">{{ data.button_4 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="homepage-footer">
        <div class="d-md-none footer-row footer-row--info">
          <div class="address address--mob">
            <span>
              Universidade Católica Portuguesa<br />
              Palma de Cima<br />
              1469-023 Lisboa<br />
              Portugal
            </span>
          </div>
        </div>
        <div class="footer-row">
          <ul class="footer-row--social">
            <li>
              <a href="https://www.ucp.pt/" class="ucp-footer__social-link">website</a>
            </li>
            <li>
              <a href="https://www.facebook.com/universidadecatolicaportuguesaoficial/"
                class="ucp-footer__social-link">facebook</a>
            </li>
            <li>
              <a href="https://www.instagram.com/universidadecatolicaportuguesa/">instagram</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/school/universidade-cat%C3%B3lica-portuguesa"
                class="ucp-footer__social-link">linkedin</a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UC_C0pjNliIPzumLvui7xMhQ"
                class="ucp-footer__social-link">youtube</a>
            </li>
          </ul>
        </div>
        <div class="footer-row footer-row--info">
          <div class="stretch copyright">
            <span>© 2023 Universidade Católica Portuguesa</span>
          </div>
          <div class="d-none d-md-block address">
            <span>Universidade Católica Portuguesa, Palma de Cima, 1469-023 Lisboa
              - Portugal</span>
          </div>
        </div>
      </footer>
    </div>

    <!--       <div class="ucp-animation ucp-content-introduction" ref="calendar">
        <ucp-calendar
          :callback="callback"
          :form-callback="formCallback"
          :mobile-callback="mobileCallback"
        />
      </div> -->
  </div>
</template>

<script>
import { isMobileOnly, deviceDetect } from "mobile-device-detect";
import ucpCalendar from "./Calendar";
import ucpMessages from "./Messages.vue";

export default {
  components: {
    ucpMessages,
    ucpCalendar,
  },
  props: {
    callback: Function,
    formCallback: Function,
    mobileCallback: Function,
    otherCallback: Function,
  },
  data() {
    return {
      isLoaded: false,
      startHeaderPos: 0,
      choice: undefined,
      data: {},
      isMobileOnly: isMobileOnly,
      isSafari: false,
      isChromeIOs: false,
      containerId: "",
      value: "",
      options: [
        "Select option",
        "options",
        "selected",
        "mulitple",
        "label",
        "searchable",
        "clearOnSelect",
        "hideSelected",
        "maxHeight",
        "allowEmpty",
        "showLabels",
        "onChange",
        "touched",
      ],
    };
  },
  methods: {
    loadData: function () {
      import(`@/data/strings/introduction.json`).then((data) => {
        this.data = data.default;
      });

      return this;
    },
    loadFlag: function (reload) {
      this.isLoaded = true;
      if (reload) {
        dispatchEvent(new Event("load"));
      }
    },
    nextSection(e) {
      e.preventDefault();

      if (!this.isMobileOnly) {
        this.callback();
      }
    },
    calendarCallback() {
      let introductionView =
        this.$root.$el.getElementsByClassName("ucp-introduction")[0];

      introductionView.scroll({
        top: introductionView.offsetHeight,
        behavior: "smooth",
      });
    },
    moveHeader() {
      let header = this.$root.$el.getElementsByClassName("ucp-header")[0];
      this.startHeaderPos = header.offsetTop;
      let introductionView =
        this.$root.$el.getElementsByClassName("ucp-introduction")[0];

      introductionView.addEventListener("scroll", () => {
        header.style.top =
          this.startHeaderPos - introductionView.scrollTop * 0.5 + "px";
      });
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!!isSafari) {
      this.isSafari = true
    }
    let isChromium = window.chrome;
    let winNav = window.navigator;
    let vendorName = winNav.vendor;
    let isOpera = typeof window.opr !== "undefined";
    let isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    let isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
      // is Google Chrome on IOS
      this.isChromeIOs = true
    } else if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      // is Google Chrome
    } else {
      // not Google Chrome
    }

    this.moveHeader();

    setTimeout(() => {
      if (!this.isLoaded) {
        this.loadFlag(true);
      }
    }, 500);

    if (this.isMobileOnly) {
      let collapseArr = Array.from(
        document.querySelectorAll(
          ".ucp-rectangle-button.collapsible"
        )
      )
      Array.from(
        document.querySelectorAll(
          ".ucp-rectangle-button.collapsible *:not(button)"
        )
      ).map((el) => {
        el.addEventListener(
          "click",
          function (ev) {
            ev.stopPropagation();
            if (
              el.closest(".ucp-rectangle-button").classList.contains('ucp-rectangle-button-collapse')
            ) {
              el.closest(".ucp-rectangle-button")
                .querySelector(".secondary-text")
                .classList.remove("collapse");
              el.closest(".ucp-rectangle-button").classList.remove("ucp-rectangle-button-collapse");
            } else {
              collapseArr.map(e => {
                if (e.classList.contains('ucp-rectangle-button-collapse')) {
                  e.classList.remove('ucp-rectangle-button-collapse')
                };
                if (e.querySelector('.secondary-text').classList.contains('collapse')) {
                  e.querySelector('.secondary-text').classList.remove('collapse')
                };
              })
              el.closest(".ucp-rectangle-button")
                .querySelector(".secondary-text")
                .classList.toggle("collapse");
              el.closest(".ucp-rectangle-button").classList.toggle("ucp-rectangle-button-collapse");
            }
          },
          false
        );
      });
    }
    window.addEventListener("load", (event) => {
      this.loadFlag(false);

      let spanHeight = window
        .getComputedStyle(
          document.querySelector(".ucp-rectangle-button--main-title span")
        )
        .getPropertyValue("line-height");
      spanHeight = parseInt(spanHeight);
      let rectanglesArray = Array.from(
        document.querySelectorAll(".ucp-rectangle-button--main-title")
      );
      let heightArray = rectanglesArray.map((e) => {
        return e.querySelectorAll("span").length;
      });
      rectanglesArray.map((e) => {
        e.style.height = `${Math.max(...heightArray) * spanHeight}px`;
      });
      Array.from(document.querySelectorAll(".ucp-rectangle-button")).map(
        (elem, i) => {
          elem.classList.add("ready-to-load");
        }
      );
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.ucp-introduction {
  min-height: 100vh;
  padding-bottom: 0;
  position: relative;
  overflow: auto;

  .messages-container {
    display: none;
  }

  /*     &::before {

      content: '';
      height: 100%;
      position: absolute;
      width: 100%;

      @include media-breakpoint-up(md) {
        //background: transparent url('~@/img/banner generico.jpg') no-repeat 0 10vh / auto 100vh;
      }
      @include media-breakpoint-up(lg) {
        //background: transparent url('~@/img/banner generico.jpg') no-repeat 0 0 / auto 130vh;
      }
      @include media-breakpoint-up(xl) {
        //background: transparent url('~@/img/banner generico.jpg') no-repeat 14vw 0 / auto 130vh;
      }
    } */

  @include media-breakpoint-up(md) {
    overflow-x: hidden;
  }

  .ucp-introduction-img-banner {

    min-height: 80vh;
    overflow: hidden;
    position: relative;

    @media only screen and (min-width: 365px) {}

    @media (min-width: 364px) and (min-height: 600px) and (max-height: 670px) {
      min-height: 85vh;
    }

    @include media-breakpoint-up(sm) {
      //min-height: 55vh;
      //background: transparent url("~@/img/banner-generico.jpg") no-repeat;
      //background-position-x: center;
    }

    @include media-breakpoint-up(md) {
      height: 65vh;
      min-height: 65vh;
      //background-position-x: 40%;
    }

    @include media-breakpoint-up(lg) {
      height: 55vh;
      //background-position-x: center;
    }

    @media (min-width: 992px) and (max-height: 1300px) {
      height: 53vh;
      min-height: 53vh;
    }

    @media (min-width: 992px) and (max-height: 801px) {
      height: 68vh;
      min-height: 68vh;
    }

    @include media-breakpoint-up(xl) {
      //background-position-y: 40%;
    }

    .join-us-mobile {
      animation: discoverin 2s forwards;
      animation-delay: 1s;
      bottom: rem(30);
      opacity: 0;
      position: absolute;
      right: rem(5);
      width: rem(80);
      z-index: 1;
    }
  }

}

.ucp-triangle-name {
  color: theme-color(black);
  bottom: rem(20);
  display: inline-block;
  font-size: rem(13);
  height: min-content;
  left: rem(33);
  position: absolute;
  width: fit-content;
  z-index: 1;

  @include media-breakpoint-up(md) {
    font-size: rem(16);
    line-height: normal;
    padding-right: 0;
    right: auto;
    top: auto;
    transform: rotate(0);
  }

  @include media-breakpoint-up(lg) {
    left: rem(90);
  }

  @include media-breakpoint-up(xl) {
    left: rem(80);
  }

  span {
    font-weight: 700;
  }

  .person-position {
    margin-left: rem(5);
    font-weight: $font-weight-base;
  }
}

.ucp-triangle-start {
  @include svg-standalone(80, theme-color(yellow), normal, true);
  color: theme-color(blue);
  display: none;
  left: 12vw;
  line-height: 1;
  font-size: rem(12);
  padding: rem(22) 0 0 rem(10);
  position: absolute;
  text-transform: uppercase;
  top: 40vh;
  z-index: 1;

  span {
    display: block;
    font-weight: 400;
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(140, theme-color(yellow), normal, true);
    left: 48vw;
    font-size: rem(20);
    padding: rem(40) rem(20) 0 rem(16);
    top: 10vh;
  }
}

.ucp-triangle-introduction {
  bottom: 5%;
  color: theme-color(black);
  height: fit-content;
  left: rem(30);
  padding-right: rem(24);
  position: absolute;
  text-transform: uppercase;
  transition: transform 0.8s ease-in-out;

  @media (min-width: 364px) and (max-width: 380px) and (max-height: 670px) {
    bottom: -2% !important;
  }

  @media only screen and (min-width: 364px) {
    bottom: 20%;
  }

  @include media-breakpoint-up(sm) {
    //left: 40vw;
  }

  @include media-breakpoint-up(md) {
    bottom: 5%;
    left: 50vw;
    padding-right: 0;
  }

  @include media-breakpoint-up(lg) {
    bottom: 5%;
    left: 50vw;
    padding-right: 0;
  }

  @media only screen and (min-width: 1919px) {}

  .ucp-triangle-introduction--wrapper {
    position: relative;

    @include media-breakpoint-down(sm) {
      >span {
        transform: translate(0, rem(-100));

        @media (min-height: 685px) {
          transform: unset;
        }
      }
    }

    @media (max-width: 380px) and (max-height: 645px) {
      >span {
        transform: translate(0px, rem(-130)) !important;
      }
    }

    @media (min-width: 355px) and (max-height: 620px) {
      >span {
        transform: translate(0px, rem(-45)) !important;
      }
    }

    @media (max-width: 365px) {
      >span {
        transform: translate(0, rem(-115));
      }
    }

    @media (max-width: 365px) and (min-height: 644px) {
      >span {
        transform: translate(0, rem(-60)) !important;
      }
    }

    .images-wrapper {
      align-items: center;
      bottom: 0;
      display: flex;
      left: 0;
      position: absolute;
      justify-content: center;
      right: 0;
      top: 0;

      .student-wrapper {
        height: 100%;
        position: relative;
        width: 100%;

        img {
          height: auto;
          left: 155%;
          position: absolute;
          width: rem(350);
          z-index: -1;
          top: -20%;

          @media only screen and (min-width: 364px) {
            top: unset;
          }

          @media (max-width: 380px) and (max-height: 670px) {
            left: 210%;
            top: -15%;
            width: rem(280);
          }

          @include media-breakpoint-up(sm) {
            left: 260%;
          }

          @include media-breakpoint-up(md) {
            left: -210%;
          }

          @include media-breakpoint-up(xl) {
            left: -170%;
            top: -10%;
            width: rem(400);
          }
        }
      }

      .rings-wrapper {
        position: relative;
        margin-left: -200%;
        margin-right: -145%;
        z-index: -2;

        @include media-breakpoint-up(sm) {

          margin-right: -65%;
        }

        @include media-breakpoint-up(lg) {
          margin-right: -90%;
        }

        @include media-breakpoint-up(xl) {
          margin-left: -300%;
          margin-right: -120%;
        }

        >img {
          height: 100%;
          object-fit: contain;
          transform: translate(0, -45px) rotate(-70deg);
          width: 100%;

          @media only screen and (max-width: 365px) {
            transform: translate(0, -85px) rotate(-70deg);
          }

          @include media-breakpoint-up(sm) {
            transform: scale(1.4) rotate(-70deg) translate(30px, 110px);
          }

          @media (min-width: 364px) and (min-height: 576px) and (max-height: 670px) {
            transform: translate(0, -85px) rotate(-70deg);
          }

          @include media-breakpoint-up(md) {
            transform: rotate(-70deg);
          }

          /* or object-fit: cover; */
        }

        .join-us-wrapper {
          bottom: 85%;
          position: absolute;
          right: 15%;
          width: rem(130);

          @media only screen and (min-width: 365px) {
            bottom: 68%;
            right: 15%;
            width: rem(180);
          }

          @media (min-width: 364px) and (max-height: 670px) {
            right: 8%;
          }

          @media (max-width: 380px) and (max-height: 670px) {
            //display: none;
            bottom: 78%;
          }

          @include media-breakpoint-up(sm) {
            bottom: 75%;
            right: -40%;
          }

          @include media-breakpoint-up(md) {
            bottom: 68%;
            right: 6%;
          }

          @include media-breakpoint-up(xl) {
            bottom: 50%;
            right: 4%;
          }

          @media only screen and (min-width: 850px) {
            bottom: 43%;
            right: -2%;
            width: rem(200);
          }

          @include media-breakpoint-up(xl) {
            bottom: 45%;
            right: -6%;
            width: rem(230);
          }

          img {
            height: auto;
            max-width: 100%;

            @media (min-width: 364px) and (max-height: 670px) {
              width: rem(140);
            }
          }
        }
      }
    }
  }

  img {
    height: auto;
    margin-top: rem(10);
    padding-left: rem(4);
    width: rem(231);

    @include media-breakpoint-up(md) {
      width: rem(360);
    }

    @include media-breakpoint-up(lg) {}

    @media only screen and (min-width: 1919px) {
      width: rem(450);
    }
  }

  .text,
  .large,
  .yellow {
    display: block;
  }

  .text {
    color: theme-color(white);
    font-size: rem(13);
    line-height: 1.2;
    padding-right: rem(120);

    &.text-hilighted {
      background: rgb(130, 231, 191);
      background: linear-gradient(90deg, rgba(130, 231, 191, 1) 0%, rgba(100, 176, 208, 1) 100%);
      border-radius: rem(3);
      display: block;
      font-size: rem(13);
      font-weight: 600;
      font-family: 'URWDIN';
      margin-bottom: rem(20);
      margin-left: rem(4);
      padding: rem(4) rem(4) rem(0) rem(4);
      width: fit-content;

      @include media-breakpoint-up(md) {
        font-size: rem(15);
      }

      @media only screen and (min-width: 1919px) {
        font-size: rem(17);
      }

      &.text-hilighted--first-line {
        margin-bottom: rem(2);
      }
    }
  }

  .large {
    font-size: rem(41);
    line-height: rem(42);
    font-family: 'URWDIN';
    font-weight: 600;

    @media only screen and (min-width: 365px) {
      font-size: rem(46);
    }

    @include media-breakpoint-up(md) {
      font-size: rem(64);
      line-height: rem(55);
    }

    @include media-breakpoint-up(lg) {
      line-height: rem(55);
    }

    @include media-breakpoint-up(xxl) {}

    @media only screen and (min-width: 1919px) {}
  }

  .yellow {
    font-size: rem(46);
    color: theme-color(yellow);
  }
}

.ucp-introduction-img-banner--safari-browser:not(.ucp-introduction-img-banner--chrome-ios-browser) {
  .ucp-triangle-introduction--wrapper {
    @include media-breakpoint-down(sm) {
      >span {
        transform: translate(0, rem(-65)) !important;
      }
    }

    @media (max-width: 380px) and (max-height: 670px) {
      >span {
        transform: translate(0px, -95px) !important;
      }
    }

    @media only screen and (max-width: 365px) {
      >span {
        transform: translate(0, -80px) !important;
      }
    }
  }
}


@keyframes discoverin {
  0% {
    opacity: 0;
    transform: translateX(#{rem(10)});
  }

  80% {
    transform: translateX(0);
  }

  100% {
    opacity: 1;
  }
}

@keyframes discover {
  0% {
    transform: translateX(0);
  }

  80% {
    transform: translateX(#{rem(10)});
  }

  100% {
    opacity: 0;
  }
}

.ucp-scroll-discover {
  bottom: rem(30);
  color: theme-color(white);
  padding: 0 rem(20) 0 0;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  transition: $transition-base;

  a {
    color: theme-color(white);
  }

  span {
    font-weight: 400;
  }

  &::before {
    @include svg-standalone(14, theme-color(white), outline-white, true);
    animation: discover 2s infinite;
    content: "";
    position: absolute;
    right: rem(60);
    top: rem(30);
    transform: rotate(90deg);

    @include media-breakpoint-up(md) {
      right: 0;
      top: rem(12);
    }
  }

  @include media-breakpoint-up(md) {
    bottom: rem(40);
    color: theme-color(black);
    font-size: rem(22);
    right: rem(100);
    transform: none;

    a {
      color: theme-color(black);
    }

    &::before {
      @include svg-standalone(14, theme-color(black), outline, true);
      transform: none;
    }
  }
}

.ucp-triangle-green-one,
.ucp-triangle-green-two {
  @include svg-standalone(20, theme-color(green), normal, true);
  position: absolute;
}

.ucp-triangle-green-one {
  display: none;
  left: 40vw;
  top: 65vh;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.ucp-triangle-green-two {
  left: 12vw;
  top: 56vh;

  @include media-breakpoint-up(md) {
    left: 77vw;
    top: 10vh;
  }
}

.ucp-triangle-yellow-two,
.ucp-triangle-red-two {
  position: absolute;
}

.ucp-triangle-yellow-two {
  @include svg-standalone(42, theme-color(yellow), normal, true);
  left: 79vw;
  top: 8vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(42, theme-color(yellow), normal, true);
  }
}

.ucp-triangle-red-two {
  @include svg-standalone(32, theme-color(red), normal, true);
  display: none;
  left: 64vw;
  top: 80vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(36, theme-color(red), normal, true);
    left: 70vw;
    top: 56vh;
    width: rem(24);
  }
}

.ucp-triangle-white {
  @include svg-standalone(28, theme-color(white), normal, true);
  left: 16vw;
  position: absolute;
  top: 73vh;

  @include media-breakpoint-up(md) {
    left: 54.5vw;
    position: absolute;
    top: 58vh;
  }

  @include media-breakpoint-up(lg) {
    left: 54.5vw;
    position: absolute;
    top: 58vh;
  }

  @include media-breakpoint-up(xl) {
    left: 54.5vw;
    position: absolute;
    top: 58vh;
  }
}

.ucp-triangle-yellow-one {
  @include svg-standalone(28, theme-color(red), normal, true);
  left: 85vw;
  position: absolute;
  top: 38vh;

  @include media-breakpoint-up(md) {
    @include svg-standalone(28, theme-color(yellow), normal, true);
    left: 54.5vw;
    position: absolute;
    top: 19vh;
  }
}

/* .ucp-start-date {
  bottom: rem(20);
  color: theme-color(blue);
  font-size: rem(12);
  left: 50%;
  margin-bottom: rem(10);
  position: absolute;
  right: auto;
  text-align: center;
  transform: translateX(-50%);
  width: auto;
  z-index: 1;

  @include media-breakpoint-up(md) {
    color: theme-color(black);
    font-size: rem(14);
    left: auto;
    right: rem(80);
    text-align: left;
    transform: translateY(-30px);
  }
} */

.ucp-triangle-apply {
  @include svg-standalone(105, theme-color(red), normal, true);
  bottom: 20vh;
  cursor: pointer;
  padding: rem(37) rem(14) rem(14) rem(14);
  position: absolute;
  right: rem(130);
  text-align: right;
  transform: rotate(180deg);

  .text {
    color: theme-color(white);
    display: block;
    font-size: rem(16);
    line-height: 1;
    transform: rotate(180deg);
  }

  .text-small {
    display: block;
    font-size: 70%;
    padding-left: rem(36);
    padding-top: rem(10);
  }

  .yellow {
    color: theme-color(yellow);
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(150, theme-color(red), normal, true);
    bottom: 16vh;
    padding-top: rem(31);
    right: 24vw;

    .text {
      font-size: rem(22);
    }

    .text-small {
      font-size: rem(11);
    }
  }
}

.ucp-triangle-know-more {
  @include svg-standalone(105, theme-color(blue), normal, true);
  bottom: 20vh;
  cursor: pointer;
  padding: rem(36) rem(14) rem(14) rem(14);
  position: absolute;
  right: rem(20);

  .text {
    color: theme-color(white);
    display: block;
    font-size: rem(16);
    line-height: 1;
  }

  .yellow {
    color: theme-color(yellow);
  }

  @include media-breakpoint-up(md) {
    @include svg-standalone(150, theme-color(blue), normal, true);
    bottom: 16vh;
    padding-top: rem(43);
    right: 12vw;

    .text {
      font-size: rem(22);
    }
  }
}

.ucp-triangle-know-more-large {
  @include svg-standalone(206, theme-color(green), normal, true);
  color: theme-color(white);
  cursor: pointer;
  display: none;
  font-size: rem(30);
  left: 10vw;
  padding: rem(70) rem(20) 0 rem(30);
  position: absolute;
  top: 40vh;
  transition: transform 0.8s ease-in-out;

  &:hover {
    transform: translateX(#{rem(10)});
  }

  .text {
    display: block;
    line-height: 1;
  }

  .text--bold {
    font-weight: 500;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

/*
  mid bar gradient colors
  #101e1b
  #00e2c1
*/

.ucp-menu-introduction {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: rem(60);
  position: relative;

  &:after {
    background: rgb(130, 231, 191);
    background: linear-gradient(90deg, rgba(130, 231, 191, 1) 0%, rgba(100, 176, 208, 1) 100%);
    content: ' ';
    left: 0;
    height: rem(10);
    top: 0;
    right: 0;
    position: absolute;

  }

  @include media-breakpoint-up(md) {
    padding-top: rem(15);
    height: 35vh;
  }

  @include media-breakpoint-up(lg) {
    align-items: start;
    flex-direction: row;
    justify-content: center;
    height: 45vh;
  }

  @media (min-width: 992px) and (max-height: 1300px) {
    height: 47vh;
  }

  @media (min-width: 992px) and (max-height: 801px) {
    height: 32vh;
  }


  .buttons-wrapper {
    /* display: flex;
    flex-direction: column; */
    z-index: 1;

    >div {
      height: fit-content;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .ucp-rectangle-button--wrapper {
    flex: 1;
    flex-shrink: 0;
    min-width: 100%;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-shrink: unset;
      margin-top: rem(120);
      min-width: unset;
    }

    .ucp-rectangle-button {
      box-shadow: rgba(0, 0, 0, 0) 0px 18px 50px -10px;
      border-radius: rem(60);
      cursor: default;
      display: flex;
      flex-direction: column;
      margin: rem(15) rem(10);
      opacity: 0;
      overflow: hidden;
      padding: rem(20) rem(45);
      transition: all 1500ms;

      @include media-breakpoint-up(md) {
        bottom: 0;
        flex-direction: column-reverse;
        left: 0;
        padding: rem(32) rem(30);
        position: absolute;
        right: 0;
      }

      @include media-breakpoint-up(lg) {
        padding: rem(16) rem(20);
      }

      &:before {
        bottom: 0;
        background: linear-gradient(132.26deg, #07B1D1 13.93%, #3F4DD1 88.91%);
        border-radius: rem(60);
        content: ' ';
        left: 0;
        margin: rem(15) rem(10);
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        transition: all 1500ms;

        @include media-breakpoint-up(md) {
          margin: unset;
        }
      }

      @include media-breakpoint-up(md) {

        &.aqua,
        &.stone {
          &:after {
            background: linear-gradient(132.26deg, #07afd100 13.93%, #3f4ed100 88.91%);
            border-radius: 0 0 rem(60) rem(60);
            bottom: 0;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            content: ' ';
            height: rem(120);
            opacity: 0;
          }

          &:hover {
            &:after {
              transition: all 500ms;
              transition-delay: 500ms;
              opacity: 1;
            }
          }
        }
      }

      &.blue {
        cursor: pointer;
      }

      &:not(.blue) {

        &.stone {
          .line-viseu:not(:first-child) {
            margin-top: rem(5);
          }

          &:hover {
            .secondary-text {
              @include media-breakpoint-up(md) {

                min-height: rem(310);
                max-height: rem(310);

                @media (min-height: 825px) {
                  min-height: rem(250);
                  max-height: rem(250);
                }
              }
            }
          }
        }

        &.aqua {

          &:hover {
            @include media-breakpoint-up(md) {
              .secondary-text {
                min-height: rem(250);
                max-height: rem(250);
              }
            }
          }

        }

        &:hover {
          @include media-breakpoint-up(md) {
            &:before {
              opacity: 0;
            }

            transition: background-color 1500ms;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

            .ucp-rectangle-button--main-title {
              background: -webkit-linear-gradient(-205deg, rgba(100, 176, 208, 1) 0%, rgba(100, 176, 208, 1) 20%, rgba(130, 231, 191, 1) 100%);
              border-top: rem(1) solid rgba(100, 176, 208, 1);
              padding-bottom: rem(35);
              padding-top: rem(35);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .secondary-text {
              span {
                color: black;
              }
            }

            @include media-breakpoint-up(md) {
              .secondary-text {
                display: flex;
                min-height: rem(200);
                max-height: rem(200);

                &.applications-secondary-text {
                  /*
            @include media-breakpoint-up(md) {
              min-height: rem(300);
              max-height: rem(300);
            }

            @include media-breakpoint-up(lg) {
              min-height: rem(300);
              max-height: rem(300);
            }

            @media (min-width: 920px) and (max-height: 1080px) {
              min-height: rem(300);
              max-height: rem(300);
            }

            @media (min-width: 920px) and (max-height: 960px) {
              min-height: rem(280);
              max-height: rem(280);
            }
            @media (min-width: 920px) and (max-height: 800px) {
              min-height: rem(150);
              max-height: rem(150);
              padding-top: 125px;
              padding-right: 10px;
              overflow-y: scroll;
              overflow-x: hidden;
              &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
              }
              &::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba(0, 49, 89, 0.1);
                height: 20px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba(0, 49, 89, 0.2);
              }
              &::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 49, 89, 0.4);
              }
              &::-webkit-scrollbar-thumb:active {
                background: rgba(0, 49, 89, 0.7);
              }
            } */
                }
              }
            }
          }
        }
      }

      &.ready-to-load {
        opacity: 100;
        transition: all 1500ms;
      }

      &.ucp-rectangle-button-collapse {
        &:not(.blue) {

          &:hover {
            &:before {
              opacity: 0;
            }

            transition: background-color 1500ms;
            background-color: linear-gradient(-205deg, rgba(100, 176, 208, 0) 0%, rgba(100, 176, 208, 0) 20%, rgba(130, 231, 191, 0) 100%);
            box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

            .ucp-rectangle-button--main-title {
              background: -webkit-linear-gradient(-205deg, rgba(100, 176, 208, 1) 0%, rgba(100, 176, 208, 1) 20%, rgba(130, 231, 191, 1) 100%);
              border-bottom: rem(1) solid rgba(100, 176, 208, 1);
              padding-bottom: rem(35);
              padding-top: rem(35);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .secondary-text {
              span {
                color: black;
              }
            }

            @include media-breakpoint-up(md) {
              .secondary-text {
                display: flex;

                @include media-breakpoint-up(md) {
                  min-height: rem(200);
                  max-height: rem(200);
                }

                @include media-breakpoint-up(lg) {
                  min-height: rem(200);
                  max-height: rem(200);
                }

                &.applications-secondary-text {
                  /*
      @include media-breakpoint-up(md) {
        min-height: rem(300);
        max-height: rem(300);
      }

      @include media-breakpoint-up(lg) {
        min-height: rem(300);
        max-height: rem(300);
      }

      @media (min-width: 920px) and (max-height: 1080px) {
        min-height: rem(300);
        max-height: rem(300);
      }

      @media (min-width: 920px) and (max-height: 960px) {
        min-height: rem(280);
        max-height: rem(280);
      }
      @media (min-width: 920px) and (max-height: 800px) {
        min-height: rem(150);
        max-height: rem(150);
        padding-top: 125px;
        padding-right: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: rgba(0, 49, 89, 0.1);
          height: 20px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 49, 89, 0.2);
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 49, 89, 0.4);
        }
        &::-webkit-scrollbar-thumb:active {
          background: rgba(0, 49, 89, 0.7);
        }
      } */
                }
              }
            }

          }
        }
      }

      .secondary-text {
        z-index: 10;

        span {
          transition: color 2500ms;
        }
      }

      .ucp-rectangle-button--main-title {
        color: white;
        border-bottom: rem(1) solid rgba(100, 176, 208, 0);
        display: flex;
        flex-direction: column;
        height: fit-content;
        justify-content: center;
        padding-bottom: rem(0);
        padding-top: rem(0);
        transition: all 1500ms;
      }

      .text {
        color: theme-color(white);
        display: block;
        font-size: rem(16);
        font-weight: 400;
        text-align: center;

        @include media-breakpoint-up(md) {
          font-size: rem(18);
        }
      }

      .secondary-text {
        font-family: "Open Sans";
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: rem(0);
        max-height: rem(0);
        overflow: hidden;
        text-align: center;
        transition: all 1500ms;

        * {
          line-height: rem(19);

          @media (min-width: 920px) and (max-height: 960px) {
            line-height: rem(18);
          }
        }

        &.collapse {
          min-height: rem(200);
          max-height: rem(200);
          display: flex;
        }

        &.applications-secondary-text {
          &.collapse {
            min-height: rem(320);
            max-height: rem(320);
            display: flex;
          }
        }

        /* .line-viseu {
        margin-top: rem(7);
      } */

        span {
          color: white;
          font-size: rem(14);
          font-weight: 300;

          &.bold {
            font-weight: 700;
          }

          &.reset-family {
            font-family: "Oswald";
            font-size: rem(15);
            font-weight: 500;
          }
        }

        button {
          background-color: transparent;
          border: rem(1) solid rgba(0, 0, 0, .75);
          border-radius: rem(25);
          color: rgba(0, 0, 0, .75);
          cursor: pointer;
          padding: rem(14) 0;
          margin: 0 auto;
          margin-top: rem(15);
          width: 100%;

          &:hover {
            font-weight: 700;
          }
        }

        >div {
          /*         display: flex;
        justify-content: center; */
          text-align: left;

          span:first-child {
            margin-right: rem(4);
          }
        }
      }
    }
  }

  .blue {
    //background-color: #2bb2fd;

    cursor: pointer;
  }

  .stone {
    //background-color: #1db0c2;
  }

  .ocean {
    //background-color: #003159;
  }

  .aqua {
    //background-color: #61bdaf;
  }
}

.homepage-footer {
  bottom: 0;
  font-family: "Open Sans";
  padding: rem(40) 0;
  z-index: 0;

  @include media-breakpoint-up(md) {
    position: absolute;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 rem(50) rem(30) rem(50);
  }

  .footer-row {
    display: flex;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: end;
      text-align: left;
    }

    .stretch {
      flex: 1;
    }

    .footer-row--social {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0;
      margin-top: rem(25);

      @media (min-width: 768px) and (max-height: 899px) {
        margin-bottom: rem(5);
      }

      li {
        list-style-type: none;

        &:not(:first-child) {
          margin-left: rem(20);

          @include media-breakpoint-up(lg) {
            margin-left: rem(30);
          }
        }

        a {
          color: theme-color(black);
          font-size: rem(13);
          font-weight: 600;

          @media (min-width: 768px) and (max-height: 899px) {
            font-size: rem(12) !important;
          }

          @include media-breakpoint-up(lg) {
            font-size: rem(14);
          }
        }
      }
    }

    .footer-row--info {
      display: flex;
    }

    .copyright {
      span {
        font-size: rem(13);
      }
    }

    .address {
      span {
        font-size: rem(13);

        @media (min-width: 768px) and (max-height: 899px) {
          font-size: rem(13) !important;
        }

        @include media-breakpoint-up(lg) {
          font-size: rem(16);
        }
      }
    }
  }
}

.ucp-content-introduction {
  background-color: theme-color(gray-lighter);
  min-height: rem(500);
  padding-bottom: rem(420);
  position: absolute;
  top: 40vh;
  width: 100%;

  @include media-breakpoint-up(md) {
    top: 40vh;
    padding-bottom: 0;
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }

  100% {
    margin-left: rem(-10);
    opacity: 1;
  }
}

.ucp-introduction::before,
.ucp-triangle-name,
.ucp-triangle-start,
.ucp-triangle-introduction,
.ucp-scroll-discover,
.ucp-triangle-green-one,
.ucp-triangle-green-two,
.ucp-triangle-yellow-two,
.ucp-triangle-red-two,
.ucp-triangle-white,
.ucp-triangle-yellow-one,
.ucp-triangle-know-more-large,
.ucp-start-date,
.ucp-triangle-apply,
.ucp-triangle-know-more {
  animation: intro 2s forwards;
  opacity: 0;
  margin-left: 0;
}

.ucp-introduction::before {
  animation-delay: 0.5s;
}

.ucp-triangle-name {
  animation-delay: 0.5s;
}

.ucp-triangle-start {
  animation-delay: 1.4s;
}

.ucp-triangle-introduction {
  animation-delay: 0.6s;
}

.ucp-scroll-discover {
  animation-delay: 0.8s;
}

.ucp-triangle-green-one {
  animation-delay: 1s;
}

.ucp-triangle-green-two {
  animation-delay: 1.2s;
}

.ucp-triangle-yellow-two {
  animation-delay: 1.4s;
}

.ucp-triangle-red-two {
  animation-delay: 1.6s;
}

.ucp-triangle-white {
  animation-delay: 1.8s;
}

.ucp-triangle-yellow-one {
  animation-delay: 2s;
}

.ucp-triangle-know-more-large {
  animation-delay: 1.2s;
}

.ucp-start-date {
  animation-delay: 1.4s;
}

.ucp-triangle-apply {
  animation-delay: 0.6s;
}

.ucp-triangle-know-more {
  animation-delay: 1s;
}

.font-bold {
  font-weight: 600 !important;
}
</style>
