<template>
  <div class="ucp-form-wrapper" :class="{ 'ucp-form-wrapper--gray': step > 1 && this.step !== locationLastStep }">
    <div class="ucp-form__information_labels">
      <span class="ucp-form__information_label-one">{{ data.information_1 }}</span>
      <span class="ucp-form__information_label-two">{{ data.information_2 }}</span>
      <span class="ucp-form__information_label-three">{{ data.information_3 }}</span>
    </div>
    <ucp-section :class="{ 'ucp-section--last-step': step === locationLastStep }">
      <div class="ucp-step" :class="`ucp-step--${step}`" v-if="step === 1">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-10 offset-md-1">
              <h2 class="ucp-step-level">
                <span class="ucp-step-level__number">{{ step }}</span>
                <span class="ucp-step-level__question">{{ question }}</span>
                <span class="ucp-state ucp-state--error" v-if="!$v.form.areas.required && $v.form.areas.$dirty">
                  {{ data.error_1 }}
                </span>
              </h2>
              <div class="ucp-step-scroll" v-if="isMobileOnly">
                <div class="ucp-form__checkbox-group">
                  <div v-for="area in areas" class="ucp-form__checkbox-wrapper">
                    <input
                      :id="area.AREA"
                      class="ucp-form__checkbox"
                      type="checkbox"
                      name="form.areas[]"
                      v-model="form.areas"
                      :value="area"
                      @change="() => { form.courses = form.locations = []; $v.form.areas.$touch() }"
                    />
                    <label :for="area.AREA" class="ucp-form__checkbox-label">
                      {{ capitalize(area.AREA) }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="ucp-step-scroll" v-else>
                <div class="ucp-form__checkbox-group">
                  <div v-for="area in areas" class="ucp-form__checkbox-wrapper">
                    <input
                      :id="area.AREA"
                      class="ucp-form__checkbox"
                      type="checkbox"
                      name="form.areas[]"
                      v-model="form.areas"
                      :value="area"
                      @change="() => { form.courses = form.locations = []; $v.form.areas.$touch() }"
                    />
                    <label :for="area.AREA" class="ucp-form__checkbox-label">{{ capitalize(area.AREA) }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ucp-nav :step="step" :show-previous="false" :disable-next="!$v.form.areas.required" :show-next="true" :step-begin="step" :step-last="locationLastStep" :callback="moveStep"></ucp-nav>
      </div>
      <div class="ucp-step" :class="`ucp-step--${step}`" v-if="step === 2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-10 offset-md-1">
              <h2 class="ucp-step-level">
                <span class="ucp-step-level__number">{{ step }}</span>
                <span class="ucp-step-level__question">{{ question }}</span>
                <span class="ucp-state ucp-state--error" v-if="!$v.form.courses.required && $v.form.courses.$dirty">Campo obrigatório</span>
              </h2>
              <div class="ucp-step-scroll ucp-step-scroll--mobile" v-if="isMobileOnly || windowWidth < 769">
                <div class="ucp-form__checkbox-group">
                  <div v-for="course in courses" class="ucp-form__checkbox-wrapper">
                    <input
                      :id="course.CURSO"
                      class="ucp-form__checkbox"
                      type="checkbox"
                      name="form.courses[]" v
                      v-model="form.courses"
                      :value="course"
                      @change="() => { form.locations = []; $v.form.courses.$touch() }"
                    />
                    <label :for="course.CURSO" class="ucp-form__checkbox-label">
                      {{ course.CURSO }} -
                      <span class="ucp-form__checkbox-label--small" v-for="location in course.LOCATIONS"> {{ capitalize(location.CIDADE) }} </span></label>
                  </div>
                </div>
              </div>
              <perfect-scrollbar class="ucp-step-scroll" v-else v-scroll-stop>
                <div class="ucp-form__checkbox-group">
                  <div v-for="course in courses" class="ucp-form__checkbox-wrapper">
                    <input
                      :id="course.CURSO"
                      class="ucp-form__checkbox"
                      type="checkbox"
                      name="form.courses[]"
                      v-model="form.courses"
                      :value="course"
                      @change="() => { form.locations = []; $v.form.courses.$touch() }"
                    />
                    <label :for="course.CURSO" class="ucp-form__checkbox-label">
                      {{ course.CURSO }} -
                      <span class="ucp-form__checkbox-label--small" v-for="location in course.LOCATIONS">
                        {{ capitalize(location.CIDADE) }}
                      </span>
                    </label>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
        <ucp-nav :step="step" :show-previous="true" :disable-next="!$v.form.courses.required" :show-next="true" :step-begin="step" :step-last="locationLastStep" :callback="moveStep"></ucp-nav>
      </div>
      <div
        class="ucp-step"
        v-for="course in selectedCoursesMultiLocation"
        :class="`ucp-step--${step}`"
        v-if="step >= locationsStep && form.activeCourse === course"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-10 offset-md-1">
              <h2 class="ucp-step-level">
                <span class="ucp-step-level__number">{{ step }}</span>
                <span class="ucp-step-level__question">
                  <v-string :text="data.question_location || ''">
                    <i slot="campus">{{ data.question_location_campus }}</i>
                  </v-string>
                </span>
                <span class="ucp-state ucp-state--error" v-if="!$v.form.locations.valid && $v.form.locations.$dirty">
                  {{ data.error_2 }}
                </span>
              </h2>
              <h3 class="ucp-location-course mb-5">
                <v-string :text="data.question_location__course || ''">
                  <span class="ucp-text-blue" slot="course">{{ course.CURSO }}</span>
                </v-string>
              </h3>
              <div class="ucp-step-scroll">
                <div class="ucp-form__checkbox-group">
                  <div v-for="location in course.LOCATIONS" class="ucp-form__checkbox-wrapper">
                    <input
                      :id="location.LINEID"
                      class="ucp-form__checkbox"
                      type="checkbox" name="form.locations[]"
                      v-model="form.locations"
                      :value="location"
                      @change="() => { $v.form.locations.$touch() }"
                    />
                    <label :for="location.LINEID" class="ucp-form__checkbox-label">
                      {{ capitalize(location.CIDADE) }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ucp-nav :step="step" :show-previous="true" :disable-next="!$v.form.locations.valid" :show-next="true" :step-begin="step" :step-last="locationLastStep" :callback="moveStep"></ucp-nav>
      </div>
      <div class="ucp-state ucp-state--error" v-if="!$v.form.locations.valid && $v.form.locations.$dirty">{{ data.error_2 }}</div>
      <div class="ucp-step" :class="`ucp-step--${step}`" v-if="step === locationLastStep">
        <div class="ucp-footer col-12 d-none d-md-block">
          <div class="ucp-footer__content">
            <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-footer__logo" />
            <address class="ucp-footer__address">
              Universidade Católica Portuguesa<br>
              Palma de Cima<br>
              1649-023 Lisboa<br>
              Portugal
            </address>
            <a href="https://www.ucp.pt/" class="ucp-footer__social-link">website</a>
            <a href="https://www.facebook.com/universidadecatolicaportuguesaoficial/" class="ucp-footer__social-link">
              facebook
            </a>
            <a href="https://www.instagram.com/universidadecatolicaportuguesa/" class="ucp-footer__social-link">
              instagram
            </a>
            <a href="https://www.youtube.com/channel/UC_C0pjNliIPzumLvui7xMhQ" class="ucp-footer__social-link">
              youtube
            </a>
            <span class="ucp-footer__copyright">© {{ new Date().getFullYear() }} Universidade Católica Portuguesa</span>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-10 offset-md-1">
              <div class="row">
                <div class="col-12 col-md-8">
                  <h2 class="ucp-step-level">
                    <span class="ucp-step-level__number">
                      {{ step }}
                    </span>
                    <span class="ucp-step-level__question">
                      {{ data.question_resume }}
                    </span>
                    <span class="ucp-state ucp-state--error" v-if="!$v.form.locations.valid && $v.form.locations.$dirty">
                      {{ data.error_2 }}
                    </span>
                  </h2>
                </div>
                <div class="col-12 col-md-4">
                  <div class="ucp-step__info-wrapper">
                    <div class="ucp-step__info" v-for="area in compiledInfo">
                      <div v-for="course in Object.values(area.COURSES)">
                        <div v-if="course.INDEX < 3" class="ucp-step__info-item">
                          {{ course.CURSO }}
                          (<span v-for="location in course.LOCATIONS"> {{ capitalize(location.CIDADE) }} </span>)
                        </div>
                        <div v-else-if="course.INDEX === 3">(...)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ucp-step-scroll ucp-step-scroll--mobile" v-if="isMobileOnly || windowWidth < 769">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-5 offset-md-1 ucp-step__form">
                <div class="form-group">
                  <input
                    class="form-control"
                    :class="{ 'form-control--filled': form.name.length }"
                    type="text"
                    required
                    id="form.name"
                    name="form.name"
                    v-model="form.name"
                    @change="() => { $v.form.name.$touch() }"
                  />
                  <label class="ucp-form__label" for="form.name">Nome*</label>
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    :class="{ 'form-control--filled': form.email.length }"
                    type="email"
                    required
                    id="form.email"
                    name="form.email"
                    v-model="form.email"
                    @change="() => { $v.form.email.$touch() }"
                  />
                  <label class="ucp-form__label" for="form.email">E-mail*</label>
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    :class="{ 'form-control--filled': form.phone.length }"
                    type="tel"
                    id="form.phone"
                    name="form.phone"
                    v-model="form.phone"
                  />
                  <label class="ucp-form__label" for="form.phone">Telefone</label>
                </div>
                <div class="form-group d-none d-md-block ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                  <input class="ucp-form__checkbox" type="checkbox" required id="form.accept" name="form.accept" v-model="form.accept" @change="$v.form.accept.$touch()" />
                  <label class="ucp-form__checkbox-label" for="form.accept">
                    <v-string :text="data.form_acceptance || ''">
                      <a href="https://www2.ucp.pt/resources/Documentos/DMC/2019/CONDIÇÕES%20DE%20CONFIDENCIALIDADE%20E%20TRATAMENTO%20DE%20DADOS%20PESSOAIS-UCP.pdf" target="_blank" slot="here">
                        {{ data.form_acceptance_link }}
                      </a>
                    </v-string>
                  </label>
                </div>
                <div class="d-none d-md-block ucp-form__description">{{ data.mandatory_fields }}</div>
              </div>
              <div class="col-12 col-md-5 ucp-step__form">
                <div class="form-group ucp-form__year">
                  <div class="ucp-form__title">Ano de frequência atual*</div>
                  <div class="ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                    <input
                      class="ucp-form__checkbox"
                      type="radio"
                      id="form.year_10"
                      name="form.year"
                      value="10" required
                      v-model="form.year"
                      @change="$v.form.year.$touch()"
                    />
                    <label class="ucp-form__checkbox-label" for="form.year_10">10.º</label>
                  </div>
                  <div class="ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                    <input
                      class="ucp-form__checkbox"
                      type="radio"
                      id="form.year_11"
                      name="form.year"
                      value="11"
                      v-model="form.year"
                      @change="$v.form.year.$touch()"
                    />
                    <label class="ucp-form__checkbox-label" for="form.year_11">11.º</label>
                  </div>
                  <div class="ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                    <input
                      class="ucp-form__checkbox"
                      type="radio"
                      id="form.year_12"
                      name="form.year"
                      value="12"
                      v-model="form.year"
                      @change="$v.form.year.$touch()"
                    />
                    <label class="ucp-form__checkbox-label" for="form.year_12">12.º</label>
                  </div>
                  <div v-if="form.year === '10'" class="ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative w-100">
                    <input
                      class="ucp-form__checkbox"
                      type="checkbox"
                      required id="form.year_confirmation"
                      name="form.year_confirmation"
                      v-model="form.year_confirmation"
                      @change="$v.form.year_confirmation.$touch()"
                    />
                    <label class="ucp-form__checkbox-label" for="form.year_confirmation">{{ data.form_year_confirmation }}*</label>
                  </div>
                </div>
                <div class="form-group">
                  <textarea class="form-control" :class="{ 'form-control--filled': form.message.length }" id="form.message" name="form.message" v-model="form.message" />
                  <label class="ucp-form__label" for="form.message">Mensagem</label>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="ucp-state ucp-state--form ucp-state--error ml-0" v-if="disableForm() || $v.form.$invalid">{{ data.error_3 }}</div>
                    </div>
                    <div class="col-12 col-md-6 text-right">
                      <button class="ucp-form__submit" :disabled="disableForm() || $v.form.$invalid" @click="submit()">
                        {{ data.form_send }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="successMessage" class="msg">Contacto enviado com sucesso.</div>
              <div v-if="errorMessage" class="msg">Ups, algo falhou, pff entre em contacto com a direcção.</div>
              <div class="d-md-none col-12 col-md-5 offset-md-1 ucp-step__form">
                <div class="form-group ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                  <input
                    class="ucp-form__checkbox"
                    type="checkbox"
                    required
                    id="form.accept"
                    name="form.accept"
                    v-model="form.accept"
                    @change="$v.form.accept.$touch()"
                  />
                  <label class="ucp-form__checkbox-label" for="form.accept">
                    <v-string :text="data.form_acceptance || ''">
                      <a
                        href="https://www2.ucp.pt/resources/Documentos/DMC/2019/CONDIÇÕES%20DE%20CONFIDENCIALIDADE%20E%20TRATAMENTO%20DE%20DADOS%20PESSOAIS-UCP.pdf"
                        target="_blank"
                        slot="here"
                      >
                        {{ data.form_acceptance_link }}
                      </a>
                    </v-string>
                  </label>
                </div>
                <div class="ucp-form__description">{{ data.mandatory_fields }}</div>
              </div>
            </div>
          </div>
        </div>
        <perfect-scrollbar class="ucp-step-scroll" v-else v-scroll-stop>
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-5 offset-md-1 ucp-step__form">
                <div class="form-group">
                  <input
                    class="form-control"
                    :class="{ 'form-control--filled': form.name.length }"
                    type="text"
                    required
                    id="form.name"
                    name="form.name"
                    v-model="form.name"
                    @change="() => { $v.form.name.$touch() }"
                  />
                  <label class="ucp-form__label" for="form.name">Nome*</label>
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    :class="{ 'form-control--filled': form.email.length }"
                    type="email"
                    required
                    id="form.email"
                    name="form.email"
                    v-model="form.email"
                    @change="() => { $v.form.email.$touch() }"
                  />
                  <label class="ucp-form__label" for="form.email">E-mail*</label>
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    :class="{ 'form-control--filled': form.phone.length }"
                    type="tel"
                    id="form.phone"
                    name="form.phone"
                    v-model="form.phone"
                  />
                  <label class="ucp-form__label" for="form.phone">Telefone</label>
                </div>
                <div
                  class="form-group d-none d-md-block ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative"
                >
                  <input
                    class="ucp-form__checkbox"
                    type="checkbox"
                    required
                    id="form.accept"
                    name="form.accept"
                    v-model="form.accept"
                    @change="$v.form.accept.$touch()"
                  />
                  <label class="ucp-form__checkbox-label" for="form.accept">
                    <v-string :text="data.form_acceptance || ''">
                      <a href="https://www2.ucp.pt/resources/Documentos/DMC/2019/CONDIÇÕES%20DE%20CONFIDENCIALIDADE%20E%20TRATAMENTO%20DE%20DADOS%20PESSOAIS-UCP.pdf" target="_blank" slot="here">
                        {{ data.form_acceptance_link }}
                      </a>
                    </v-string>
                  </label>
                </div>
                <div class="d-none d-md-block ucp-form__description">{{ data.mandatory_fields }}</div>
              </div>
              <div class="col-12 col-md-5 ucp-step__form">
                <div class="form-group ucp-form__year">
                  <div class="ucp-form__title">Ano de frequência atual*</div>
                  <div class="ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                    <input
                      class="ucp-form__checkbox"
                      type="radio"
                      id="form.year_10"
                      name="form.year"
                      value="10"
                      required
                      v-model="form.year"
                      @change="$v.form.year.$touch()"
                    />
                    <label class="ucp-form__checkbox-label" for="form.year_10">10.º</label>
                  </div>
                  <div class="ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                    <input
                      class="ucp-form__checkbox"
                      type="radio"
                      id="form.year_11"
                      name="form.year"
                      value="11"
                      v-model="form.year"
                      @change="$v.form.year.$touch()"
                    />
                    <label class="ucp-form__checkbox-label" for="form.year_11">11.º</label>
                  </div>
                  <div class="ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                    <input
                      class="ucp-form__checkbox"
                      type="radio"
                      id="form.year_12"
                      name="form.year"
                      value="12"
                      v-model="form.year"
                      @change="$v.form.year.$touch()"
                    />
                    <label class="ucp-form__checkbox-label" for="form.year_12">12.º</label>
                  </div>
                  <div v-if="form.year === '10'" class="ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative w-100">
                    <input
                      class="ucp-form__checkbox"
                      type="checkbox"
                      required
                      id="form.year_confirmation"
                      name="form.year_confirmation"
                      v-model="form.year_confirmation"
                      @change="$v.form.year_confirmation.$touch()"
                    />
                    <label class="ucp-form__checkbox-label" for="form.year_confirmation">{{ data.form_year_confirmation }}*</label>
                  </div>
                </div>
                <div class="form-group">
                  <textarea class="form-control" :class="{ 'form-control--filled': form.message.length }" id="form.message" name="form.message" v-model="form.message" />
                  <label class="ucp-form__label" for="form.message">Mensagem</label>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="ucp-state ucp-state--form ucp-state--error" v-if="disableForm() || $v.form.$invalid">{{ data.error_3 }}</div>
                    </div>
                    <div class="col-12 col-md-6 text-right">
                      <button class="ucp-form__submit" :disabled="disableForm() || $v.form.$invalid" @click="submit()">{{ data.form_send }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-md-none col-12 col-md-5 offset-md-1 ucp-step__form">
                <div class="form-group ucp-form__checkbox-wrapper ucp-form__checkbox-wrapper--alternative">
                  <input
                    class="ucp-form__checkbox"
                    type="checkbox"
                    required
                    id="form.accept"
                    name="form.accept"
                    v-model="form.accept"
                    @change="$v.form.accept.$touch()"
                  />
                  <label class="ucp-form__checkbox-label" for="form.accept">
                    <v-string :text="data.form_acceptance || ''">
                      <a
                        href="https://www2.ucp.pt/resources/Documentos/DMC/2019/CONDIÇÕES%20DE%20CONFIDENCIALIDADE%20E%20TRATAMENTO%20DE%20DADOS%20PESSOAIS-UCP.pdf"
                        target="_blank"
                        slot="here"
                      >
                        {{ data.form_acceptance_link }}
                      </a>
                    </v-string>
                  </label>
                </div>
                <div class="ucp-form__description">{{ data.mandatory_fields }}</div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
        <ucp-nav :step="step" :show-label="step !== locationLastStep" :show-previous="true" :show-next="false" :step-begin="step" :step-last="locationLastStep" :callback="moveStep"></ucp-nav>
      </div>
    </ucp-section>
    <div class="ucp-footer col-12 d-md-none">
      <div class="ucp-footer__content">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-footer__logo" />
        <address class="ucp-footer__address">
          Universidade Católica Portuguesa<br>
          Palma de Cima<br>
          1649-023 Lisboa<br>
          Portugal
        </address>
        <div class="ucp-footer__social-links">
          <a href="https://www.ucp.pt/" class="ucp-footer__social-link">website</a>
          <a href="https://www.facebook.com/universidadecatolicaportuguesaoficial/" class="ucp-footer__social-link">
            facebook
          </a>
          <a href="https://www.instagram.com/universidadecatolicaportuguesa/" class="ucp-footer__social-link">
            instagram
          </a>
          <a href="https://www.youtube.com/channel/UC_C0pjNliIPzumLvui7xMhQ" class="ucp-footer__social-link">
            youtube
          </a>
        </div>
        <span class="ucp-footer__copyright">© {{ new Date().getFullYear() }} Universidade Católica Portuguesa</span>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import vString from "vue-sprintf-components"
import ucpSection from "@/components/Section"
import ucpNav from "@/components/Navigation"
import areas from "@/data/areas.json"
import CMIds from "@/data/listActiveCampaignIds.json"
import CMNames from "@/data/listActiveCampaign.json"
import _ from 'lodash'
import { isMobileOnly, deviceDetect } from 'mobile-device-detect'

export default {
  components: {
    vString,
    ucpSection,
    ucpNav
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      isMobileOnly: isMobileOnly,
      step: 1,
      locationsStep: 3,
      resumeIndex: 0,
      submitted: false,
      successMessage: false,
      errorMessage: false,
      areas: {},
      courses: {},
      data: {},
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
        accept: false,
        activeCourse: {},
        areas: [],
        courses: [],
        locations: [],
      },
      scrollIns: null
    };
  },
  props: {
    double: Boolean
  },
  validations: {
    form: {
      areas: {
        required,
      },
      courses: {
        required
      },
      email: {
        required,
      },
      name: {
        required,
      },
      year: {
        required,
      },
      year_confirmation: {},
      accept: {
        checked(val) {
          return val;
        },
      },
      locations: {
        valid() {
          let valid = true;

          for (let course of this.form.courses) {
            if (!(this.form.activeCourse && course === this.form.activeCourse)) continue;

            if (Object.keys(course.LOCATIONS).length > 1) {
              valid = false;

              for (let location of this.form.locations) {
                if (location.COURSEID === course.COURSEID) {
                  valid = true;
                }
              }
            }
          }

          return valid;
        }
      }
    }
  },
  methods: {
    capitalize(string) {
      return string.toLowerCase().split(' ')
        .map(function (word) {
          // exclude portuguese words with less then 3 characters like "de", "e", etc
          return word.length < 3 ? word.toLowerCase() : word[0].toUpperCase() + word.substr(1);
        })
        .join(' ')
        ;
    },
    updateFormAreaCourses() {
      this.courses = {};

      this.form.areas.forEach((area) => {
        Object.values(area.COURSES).forEach((course) => {
          this.courses[course.CURSO] = course;
        });
      });
    },
    disableForm() {
      if (this.form.year === '10' && this.form.year_confirmation !== true) {
        return true;
      }

      return false;
    },
    submit() {
      this.submitted = true;
      let courses = [];
      let listsID = [];

      // SendInBlue FORM link
      // Optional ?isAjax=1 query param at end of link, for ajax request
      let link = "https://4e63b65a.sibforms.com/serve/MUIEACJzDvpDgLZheIt8gPoFPM-h5dfNQJu9WpMMogJ7L_OanO-dSoh9nQGASDKX2fjb998tSbhd4EZbCIYS2s8fZ0WuL1Xq0uacb8WegHjt-iBJTERX5scMfPeh6lpK3rGWZvLBBeokqfH4iTCIrEBDcmok6oy5qkV85BkjG9AxnGP9oY3QuafRSClVxo6-SyGHRkOC4meTefY3";

      // Workaround for form, this cannot be done via ajax due to captcha pag,
      // [DEPRECATED IN SENDINBLUE BUT PRESERVED BEHAVIOUR]
      let form = $('<form />', {
        action: link,
        method: "POST",
        style: 'display: none;'
      });

      // Sendinblue does not support dynamic multi fields, so we have to concat the values in a list
      for (let area of this.compiledInfo) {
        for (let course of Object.values(area.COURSES)) {
          for (let location of Object.values(course.LOCATIONS)) {

            let listID = '';
            switch (location.CIDADE) {
              case 'LISBOA':
                listID = 68;
                break;
              case 'BRAGA':
                listID = 70;
                break;
              case 'PORTO':
                listID = 69;
                break;
              case 'VISEU':
                listID = 71;
                break;
              case 'lecionação plurilocalizada':
              case 'Ensino à distância':
                listID = 15;
                break;
              default:
                console.log('Location unknown.');
            }

            listsID.push(listID);

            let course = CMNames[location.LINEID];
            courses.push(course);

            $('<input />', {
              type: 'hidden',
              name: 'lists_39[]',
              value: listID
            }).appendTo(form);
          }
        }
      }

      // Contact fields
      const newContact = {
        EMAIL: this.form.email,
        OPT_IN: this.form.accept ? 1 : 0,
        NOME: this.form.name,
        TELEMOVEL: this.form.phone,
        ANO: this.form.year + 'º ',
        ANO_MAIS_16_CONFIRMACAO: this.form.year_confirmation ? 1 : 0,
        MENSAGEM: this.form.message,
        CURSOS: courses.join(' | '),
        email_address_check: '',
        html_type: 'simple'
      };

      for (let key in newContact) {
        let value = newContact[key];

        $('<input />', {
          type: 'hidden',
          name: key,
          value: value
        }).appendTo(form);
      }

      form.appendTo('body').submit();
    },
    moveStep(step) {
      this.$router.push({ name: this.$router.currentRoute.name, hash: '#step' + step }).catch(() => { });

      this.step = step;
      this.form.activeCourse = undefined;

      if (step >= this.locationsStep && (step - this.locationsStep) >= 0) {
        this.$v.form.locations.$reset();
        this.form.activeCourse = this.selectedCoursesMultiLocation[step - this.locationsStep]
      }

      if (step === 2) this.updateFormAreaCourses();

      // Scroll view to top of ucp-form element.
      if (this.isMobileOnly) {
        let homeView = this.$root.$el.getElementsByClassName('ucp-home')[0];
        let formSection = this.$root.$el.getElementsByClassName('ucp-form')[0];

        homeView.scroll({
          top: formSection.offsetTop,
          behavior: 'smooth'
        });
      }
    },
    loadData() {
      import(`@/data/strings/form.json`).then((data) => {
        this.data = data.default;
      });

      import(`@/data/areasFormatted.json`).then((data) => {
        this.areas = data.default;
      });

      return this;
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isMobileOnly = deviceDetect().isMobileOnly;
    });
  },
  computed: {
    colorRand: () => {
      const r = () => Math.floor(256 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },
    question: ({ data, step }) => {
      return data[`question_${step}`];
    },
    selectedCoursesMultiLocation: ({ form }) => {
      return form.courses.filter((course) => Object.keys(course.LOCATIONS).length > 1);
    },
    locationLastStep: ({ locationsStep, selectedCoursesMultiLocation }) => {
      return locationsStep + selectedCoursesMultiLocation.length;
    },
    compiledInfo: ({ form }) => {
      let compiled = [];
      let index = 0;

      for (let area of form.areas) {
        let compiledArea = {
          ID: area.ID,
          LINEID: area.LINEID,
          AREA: area.AREA,
          COURSES: {}
        };

        for (let course of form.courses) {
          if (course.ID !== area.ID) continue;

          compiledArea.COURSES[course.CURSO] = {
            INDEX: index++,
            LINEID: course.LINEID,
            CURSO: course.CURSO,
            LOCATIONS: {}
          };

          let hasMultipleLocations = Object.keys(course.LOCATIONS).length > 1;
          let locations = hasMultipleLocations ? form.locations : Object.values(course.LOCATIONS);

          for (let location of locations) {
            if (location.COURSEID !== course.COURSEID) continue;

            compiledArea.COURSES[course.CURSO].LOCATIONS[location.CIDADE] = {
              LINEID: location.LINEID,
              CIDADE: location.CIDADE
            };
          }
        }

        compiled.push(compiledArea);
      }

      return compiled;
    }
  }
}
</script>

<style lang="scss" scoped>
.ucp-section {
  z-index: 1;

  @include media-breakpoint-up(md) {
    height: calc(100vh - #{rem(180)});
    position: relative;
  }
}

.ucp-form-wrapper {
  padding-top: rem(130);
  transition: $transition-base;

  @include media-breakpoint-up(md) {
    padding-top: rem(180);
  }

  &.ucp-form-wrapper--gray {
    background-color: theme-color(gray-lighter);
  }
}

.ucp-form .ucp-section--last-step {
  @include media-breakpoint-up(md) {
    width: 70vw;
  }
}

.ucp-form__information_labels {
  font-size: rem(20);
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: rem(30);
  padding-left: rem(35);
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(md) {
    position: absolute;
    left: calc(100vw - #{rem(208)});
    max-width: rem(200);
    text-align: right;
    top: rem(30);
    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: rem(30);
  }
}

.ucp-form__information_label-one {
  display: block;

  &::before {
    @include svg-standalone(12, theme-color(blue), normal, true);
    content: '';
    left: rem(14);
    position: absolute;
    top: rem(8);

    @include media-breakpoint-up(md) {
      left: rem(8);
    }
  }
}

.ucp-form__information_label-one {
  display: block;
}

.ucp-form__information_label-two {
  display: block;
  font-weight: 400;
}

.ucp-form__information_label-three {
  display: block;
  font-weight: 400;
}

.ucp-step-scroll {
  @include media-breakpoint-up(md) {
    height: calc(100vh - #{rem(380)});
  }

  .ucp-section--last-step & {
    @include media-breakpoint-up(md) {
      margin-right: rem(60);
    }
  }
}

.ucp-location-course {
  font-size: rem(18);
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    font-size: rem(24);
  }
}

.ucp-step-level {
  line-height: 1.2;
  margin-bottom: rem(20);
  padding-left: rem(10);

  @include media-breakpoint-up(md) {
    font-size: rem(40);
    margin-bottom: rem(30);
    position: relative;
    padding-left: 0;
  }
}

.ucp-state {
  display: block;
  font-size: rem(14);
  margin-left: rem(37);

  @include media-breakpoint-up(md) {
    bottom: rem(-26);
    left: rem(46);
    margin-left: rem(5);
    position: absolute;
  }
}

.ucp-step-level__number {
  font-weight: 400;
  position: relative;
  margin-right: rem(20);

  &::before {
    @include svg-standalone(34, #61bdaf, normal, true);
    content: '';
    left: rem(-14);
    position: absolute;
    top: 0;
    z-index: -1;
  }

  &::after {
    content: '.';
  }
}

.ucp-step-level__question {}

.ucp-state {}

.ucp-state--form {
  position: static;
}

.ucp-state--error {
  color: theme-color(red);
}

.ucp-step__info-wrapper {
  margin-bottom: rem(20);
  overflow-x: auto;
  padding-right: rem(20);
  position: relative;

  @include media-breakpoint-up(md) {
    max-height: none;
    padding-right: 0;
  }
}

.ucp-step__info {
  color: theme-color(blue);
  font-size: rem(14);
  font-weight: 400;

  span {
    font-weight: 300;

    &:not(:last-child)::after {
      content: ', ';
    }
  }
}

.ucp-step__form {
  .form-control {
    border-width: 0 0 rem(1);
    box-sizing: content-box;
    padding: rem(20) 0 0;
  }

  textarea {
    height: rem(140);
    resize: none;
  }

  .ucp-form__checkbox-wrapper {
    width: 100%;

    .ucp-form__checkbox-label {
      font-family: $font-family-monospace;
      font-size: rem(13);
    }
  }
}

.ucp-form__checkbox-wrapper {
  @include custom-input(checkbox, 18px);
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 40%;
  }
}

.ucp-form__checkbox-wrapper--alternative {
  .ucp-form__checkbox-label {
    &::before {
      border: rem(1) solid theme-color(gray);
      border-radius: rem(4);
      top: rem(10)
    }

    &::after {
      background-color: theme-color(blue);
      background-image: none;
      border-radius: rem(4);
      mask-image: none;
      top: rem(10);
    }
  }

  .ucp-form__checkbox:checked~.ucp-form__checkbox-label {
    color: theme-color(black);

    &::after {
      opacity: 1;
    }
  }
}

.ucp-form__checkbox-label {
  margin-bottom: rem(10);
  margin-top: rem(10);
}

.ucp-form__checkbox-label--small {
  font-size: rem(18);

  &:not(:last-child)::after {
    content: ', ';
  }
}

.ucp-form__description {
  color: theme-color(gray);
  font-family: $font-family-monospace;
  font-size: rem(13);
}

.form-group {
  position: relative;

  .form-control:focus,
  .form-control--filled {
    ~.ucp-form__label {
      transform: translateY(#{rem(-20)});
      font-size: rem(14);
    }
  }
}

.ucp-form__label {
  display: block;
  font-family: $font-family-monospace;
  margin-bottom: 0;
  position: absolute;
  top: rem(20);
  transition: $transition-base;
}

.ucp-form__title {
  display: block;
  font-family: $font-family-monospace;
  font-size: rem(16);
  margin-top: rem(25);
  margin-bottom: rem(5);
}

.ucp-form__submit {
  background-color: theme-color(blue);
  border: 0;
  border-radius: rem(10);
  box-shadow: 0 rem(2) rem(16) 0 rgba(theme-color(black), .10);
  color: theme-color(white);
  font-size: rem(20);
  font-weight: 400;
  padding: rem(4) rem(20) rem(8);
  transition: $transition-base;

  &:disabled {
    background-color: rgba(theme-color(blue), .5);
  }

  &:hover:not(:disabled) {
    background-color: darken(theme-color(blue), 10%);
  }
}

.ucp-form__year {
  .ucp-form__checkbox-wrapper--alternative {
    width: 25%;
  }
}

.ucp-footer {
  background-color: theme-color(gray-light);
  margin-top: rem(30);
  padding-top: rem(40);

  @include media-breakpoint-up(md) {
    height: 100vh;
    margin-top: 0;
    padding: rem(180) rem(40) rem(40);
    position: fixed;
    right: -30vw;
    text-align: right;
    top: 0;
    width: 30vw;
    z-index: -1;
  }
}

.ucp-footer__content {
  margin-top: rem(30);
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: right;
    bottom: rem(40);
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{rem(180)});
    justify-content: flex-end;
    position: absolute;
    width: calc(100% - #{rem(80)});
  }
}

.ucp-footer__logo {
  filter: brightness(0);
  margin-bottom: rem(40);
  max-width: 220px;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
}

.ucp-footer__address {
  font-size: rem(16);
  font-weight: 400;
  margin-bottom: rem(60);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(80);
  }
}

.ucp-footer__social-links {
  @include media-breakpoint-up(md) {
    text-align: right;
  }
}

.ucp-footer__social-link {
  color: theme-color(black);
  display: inline-block;
  font-weight: 600;
  margin: 0 5px;

  @include media-breakpoint-up(md) {
    display: block;
    margin: 0;
    font-weight: 400;
  }
}

.ucp-footer__copyright {
  display: block;
  font-size: rem(13);
  padding-top: rem(30);
  padding-bottom: rem(15);

  @include media-breakpoint-up(md) {
    padding-top: rem(60);
    padding-bottom: 0;
  }
}

@keyframes formchecks {
  0% {
    opacity: 0;
    transform: translateX(#{rem(-10)});
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes formclaststep {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

$stages: 20; // Number of race stages
$timelapse: .1s; // Time to start next stage animation


[class*="ucp-step--"] {
  .ucp-form__checkbox-group .ucp-form__checkbox-wrapper {
    opacity: 0;
    animation-name: formchecks;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-play-state: running;

    @for $i from 1 through $stages {
      &:nth-child(#{$i}) {
        animation-delay: $i * $timelapse;
      }
    }

    .ucp-section--last-step & {
      animation: none;
    }
  }
}

.ucp-section--last-step .ucp-step {
  animation: formclaststep .5s forwards;
  opacity: 0;
}
</style>
