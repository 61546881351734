<template>
  <div class="ucp-links">
    <header class="ucp-header d-md-none">
      <router-link :to="{ name: 'home' }">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-header__logo" />
      </router-link>
    </header>
    <div class="container">
      <div class="row">
        <div class="col text-center thanks-text">
          <p class="message-one">{{ data.thanks_1 }}</p>
          <p class="message-two">{{ data.thanks_2 }}</p>
          <p class="message-three">
            <v-string :text="data.thanks_3 || ''">
              <a href="https://www.ucp.pt/" target="_blank" slot="url">{{
                data.thanks_3_url
              }}</a>
            </v-string>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vString from "vue-sprintf-components";

export default {
  name: "thanks",
  components: {
    vString,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    loadData: function () {
      import(`@/data/strings/thanks.json`).then((data) => {
        this.data = data.default;
      });

      return this;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.ucp-links {
  @include media-breakpoint-up(md) {
    padding-top: rem(200);
  }
}

.message-one {
  font-size: rem(42);
  font-weight: 400;
  line-height: 1;
  margin: rem(40) 0;
  margin-top: rem(130) !important;
  @include media-breakpoint-up(md) {
  margin-top: rem(40);
  }
}

.message-two,
.message-three {
  font-size: rem(22);

  @include media-breakpoint-up(md) {
    font-size: rem(28);
  }
}

a {
  transition: $transition-base;

  &:hover {
    color: theme-color(black);
  }
}
</style>
