<template>
  <div class="ucp-links-lisbon">
    <header class="ucp-header d-md-none">
      <router-link :to="{ name: 'home' }">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-header__logo" />
      </router-link>
    </header>
    <div class="container-fluid ucp-links-lisbon-wrapper">
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1">
          <div class="row" v-if="step !== ''">
            <div class="col mb-2">
              <a @click="step = ''" class="ucp-btn--prev">coisa<span class="ucp-btn__icon"></span> {{ data.previous }}</a>
            </div>
          </div>
          <div class="row ucp-links-lisbon-content" v-if="step === ''">
            <div class="col-12 col-md-8">
              <p class="ucp-link ucp-text-info">
                <span>Para todas as licenciaturas, com exceção das abaixo
                  indicadas:</span>
              </p>
              <p class="p-button-wrapper">
                <button class="ucp-links-lisbon__button">
                  <a :href="data.link_general_lisboa_b" target="_blank">
                    <span>CANDIDATAR-ME</span></a>
                </button>
              </p>
              <p>
                <a :href="data.links_not_general_lisboa" target="_blank" class="ucp-link"><span>{{
                  data.not_general_lisboa1 }}</span></a>
              </p>
              <p>
                <a :href="data.links_not_general_lisboa" target="_blank" class="ucp-link"><span>{{
                  data.not_general_lisboa2 }}</span></a>
              </p>
              <p>
                <a :href="data.link_medicina" target="_blank" class="ucp-link"><span>{{ data.not_general_lisboa3
                }}</span></a>
              </p>
            </div>
          </div>

          <!-- Lisboa -->
          <!--          <div class="row ucp-links-step" v-if="step === data.city1">-->
          <!--            <div class="col-4">-->
          <!--              <a :href="data.city1_1_url" target="_blank" class="ucp-link">{{ data.city1_1 }}</a>-->
          <!--            </div>-->
          <!--            <div class="col-6">-->
          <!--              <a :href="data.city1_2_url" target="_blank" class="ucp-link">{{ data.city1_2 }}</a>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <div class="ucp-footer col-12 d-none d-md-block">
      <div class="ucp-footer__content">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-footer__logo" />
        <address class="ucp-footer__address">
          Universidade Católica Portuguesa<br />
          Palma de Cima<br />
          1649-023 Lisboa<br />
          Portugal
        </address>

        <a href="https://www.ucp.pt/" class="ucp-footer__social-link">website</a>
        <a href="https://www.facebook.com/universidadecatolicaportuguesaoficial/"
          class="ucp-footer__social-link">facebook</a>
        <a href="https://www.instagram.com/universidadecatolicaportuguesa/" class="ucp-footer__social-link">instagram</a>
        <a href="https://www.linkedin.com/school/universidade-cat%C3%B3lica-portuguesa"
          class="ucp-footer__social-link">linkedIn</a>
        <a href="https://www.youtube.com/channel/UC_C0pjNliIPzumLvui7xMhQ" class="ucp-footer__social-link">youtube</a>

        <span class="ucp-footer__copyright">© {{ new Date().getFullYear() }} Universidade Católica
          Portuguesa</span>
      </div>
    </div>

    <div class="ucp-footer col-12 d-md-none">
      <div class="ucp-footer__content">
        <img src="@/img/logo-ucp.png" alt="" class="img-fluid ucp-footer__logo" />
        <address class="ucp-footer__address">
          Universidade Católica Portuguesa<br />
          Palma de Cima<br />
          1649-023 Lisboa<br />
          Portugal
        </address>

        <div class="ucp-footer__social-links">
          <a href="https://www.ucp.pt/" class="ucp-footer__social-link">website</a>
          <a href="https://www.facebook.com/universidadecatolicaportuguesaoficial/"
            class="ucp-footer__social-link">facebook</a>
          <a href="https://www.instagram.com/universidadecatolicaportuguesa/"
            class="ucp-footer__social-link">instagram</a>
          <a href="https://www.linkedin.com/school/universidade-cat%C3%B3lica-portuguesa"
            class="ucp-footer__social-link">linkedIn</a>
          <a href="https://www.youtube.com/channel/UC_C0pjNliIPzumLvui7xMhQ" class="ucp-footer__social-link">youtube</a>
        </div>

        <span class="ucp-footer__copyright">© {{ new Date().getFullYear() }} Universidade Católica
          Portuguesa</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "links",
  components: {},
  data() {
    return {
      step: "",
      data: {},
    };
  },
  methods: {
    loadData: function () {
      import(`@/data/strings/links.json`).then((data) => {
        this.data = data.default;
      });

      return this;
    },

    locationPage() {
      this.delayedStepLinks++;
      setTimeout(() => this.$router.push({ name: "links" }), 1000);
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    // Clear header raw styles
    let header = this.$root.$el.getElementsByClassName("ucp-header")[0];
    if (header.hasAttribute("style")) header.removeAttribute("style");
  },
};
</script>

<style lang="scss" scoped>
@keyframes intro {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ucp-links-lisbon {
  padding-top: rem(130);

  @include media-breakpoint-up(md) {
    padding-top: rem(230);
  }

  .p-button-wrapper {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .ucp-links-lisbon__button {

    background: linear-gradient(-205deg, rgba(100, 176, 208, 1) 0%, rgba(100, 176, 208, 1) 20%, rgba(130, 231, 191, 1) 100%);
    border-radius: rem(60);
    border: rem(0) solid;
    height: rem(80);
    margin: rem(45) 0 rem(30) rem(-2);
    width: 95%;

    @include media-breakpoint-up(md) {
      margin: rem(30) rem(10) rem(10) rem(-2);
      width: rem(250);
    }

    a {
      display: flex;
      height: 100%;
      width: 100%;
    }

    &:hover {
      span {
        font-weight: 500;
      }
    }

    span {
      color: theme-color(white);
      display: block;
      height: fit-content;
      margin: auto;
      width: fit-content;
    }
  }

  .ucp-links {
    animation: intro 2s;
    transition: $transition-base;

    .applications-wrapper {
      padding-top: rem(100);

      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: rem(180);
    }
  }

  .ucp-title {
    line-height: 1.2;
    margin-bottom: rem(10);
    margin-left: rem(15);
    margin-top: rem(40);
    padding-left: rem(20);
    position: relative;

    &::before {
      @include svg-standalone(34, #61bdaf, normal, true);
      content: "";
      left: rem(-14);
      position: absolute;
      top: rem(-6);
      z-index: -1;
    }

    &:after {
      content: ".1";
      font-weight: 400;
      position: absolute;
      left: 0;
    }

    @include media-breakpoint-up(md) {
      font-size: rem(40);
      margin-left: 0;
      position: relative;
      padding-left: rem(30);
    }
  }

  .ucp-sub-title {
    font-size: rem(18);
    line-height: 1.2;
    margin-bottom: rem(40);
    margin-left: rem(15);
    padding-left: rem(20);
  }

  .ucp-links-step {
    animation: intro 2s;
    transition: $transition-base;
  }

  .ucp-link {
    color: theme-color(blue);
    cursor: pointer;
    display: inline-block;
    font-size: rem(18);
    font-weight: 300;
    line-height: 1.2;
    margin: rem(10) rem(10);
    padding: 0 rem(20) 0 0;
    position: relative;
    transition: color 0.2s ease-in-out;
    vertical-align: middle;

    &.ucp-text-info {
      color: theme-color(black);
      cursor: default;
      font-weight: 400;
      padding: 0;

      &::after {
        display: none;
      }
    }

    &::after {
      @include svg-standalone(20, theme-color(blue), normal, true);
      border-radius: rem(1);
      content: "";
      height: rem(18);
      left: rem(-30);
      opacity: 0;
      position: absolute;
      top: rem(5);
      transition: $transition-base;
      width: rem(18);
    }

    &:hover {
      font-weight: 400;
    }

    &:hover::after {
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      font-size: rem(22);
    }
  }

  .ucp-footer {
    background-color: theme-color(gray-light);
    margin-top: rem(30);
    padding-top: rem(40);

    @include media-breakpoint-up(md) {
      height: 100vh;
      margin-top: 0;
      padding: rem(180) rem(40) rem(40);
      position: fixed;
      right: 0;
      text-align: right;
      top: 0;
      width: 30vw;
      z-index: 0;
    }
  }

  .ucp-footer__content {
    margin-top: rem(30);
    text-align: center;
    height: calc(100vh - #{rem(400)});

    @include media-breakpoint-up(md) {
      text-align: right;
      bottom: rem(40);
      display: flex;
      flex-direction: column;
      height: calc(100vh - #{rem(180)});
      justify-content: flex-end;
      position: absolute;
      width: calc(100% - #{rem(80)});
    }
  }

  .ucp-footer__logo {
    filter: brightness(0);
    margin-bottom: rem(40);
    max-width: 220px;

    @include media-breakpoint-up(md) {
      max-width: 100%;
    }
  }

  .ucp-footer__address {
    font-size: rem(16);
    font-weight: 400;
    margin-bottom: rem(60);

    @include media-breakpoint-up(md) {
      margin-bottom: rem(80);
    }
  }

  .ucp-footer__social-links {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  .ucp-footer__social-link {
    color: theme-color(black);
    display: inline-block;
    font-weight: 600;
    margin: 0 5px;

    @include media-breakpoint-up(md) {
      display: block;
      margin: 0;
      font-weight: 400;
    }
  }

  .ucp-footer__copyright {
    display: block;
    font-size: rem(13);
    padding-top: rem(30);
    padding-bottom: rem(15);

    @include media-breakpoint-up(md) {
      padding-top: rem(60);
      padding-bottom: 0;
    }
  }

  .ucp-btn--prev {
    cursor: pointer;
    position: relative;
    text-transform: lowercase;
    font-size: rem(16);
    padding-left: rem(30);

    @include media-breakpoint-up(md) {
      font-size: rem(20);
    }

    .ucp-btn__icon {
      @include svg-standalone(14, theme-color(black), outline, true);
      left: 0;
      position: absolute;
      transform: rotate(180deg);
      top: rem(11);
    }
  }
}
</style>
