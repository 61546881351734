<template>
  <div class="ucp-messages d-md-none" ref="scrollable">
  <div class="ucp-advance-buttons-wrapper">
    </div>

    <div class="ucp-animation ucp-skip-animation d-none d-md-block" @click="skipAnimations">
      {{ data.advance_link }}
    </div>

  </div>
</template>

<script>
import vString from "vue-sprintf-components";
import { isMobileOnly, deviceDetect } from 'mobile-device-detect';

export default {
  components: {
    vString
  },
  props: {
    callback: Function,
    formCallback: Function,
    mobileCallback: Function,
    calendarCallback: Function
  },
  data() {
    return {
      isMobileOnly: isMobileOnly,
      data: {}
    }
  },
  methods: {
    loadData() {
      import(`@/data/strings/messages.json`).then((data) => {
        this.data = data.default;
      });

      return this;
    },
    skipAnimations() {
      document.body.classList.toggle('ucp-end-animation');
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    let messages = document.querySelectorAll('.ucp-message:not(.d-md-none)');
    let lastMessage = messages[messages.length - 1];


/*     lastMessage.addEventListener('animationend', () => {
      if (!this.isMobileOnly) {
        this.callback();
      }
    }); */
  }
}
</script>

<style lang="scss" scoped>

.ucp-messages {
  padding-bottom: rem(55);
  padding-top: rem(60);
  position: relative;

  @include media-breakpoint-up(md) {
    height: 100vh;
    padding-bottom: 0;
    padding-top: 0;
    white-space: nowrap;
  }
}

.ucp-message {
  padding-right: rem(20);
  padding-bottom: rem(30);
  position: relative;

  @include media-breakpoint-up(md) {
    margin-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    position: absolute;
  }
}

.ucp-triangle--two {
  @include svg-standalone(35, theme-color(yellow), normal, true);
  bottom: rem(30);
  position: absolute;
  right: rem(30);
  transform: rotate(90deg);
  z-index: 1;

  @include media-breakpoint-up(md) {
    left: rem(150);
    top: rem(180);
    transform: none;
  }
}

.ucp-triangle--three {
  @include svg-standalone(24, theme-color(red), normal, true);
  left: rem(20);
  position: absolute;
  top: 0;
  transform: rotate(90deg);
  z-index: 1;

  @include media-breakpoint-up(md) {
    display: block;
    left: rem(-20);
    top: rem(-20);
    transform: none;
  }
}

.ucp-triangle--four {
  @include svg-standalone(45, theme-color(yellow), normal, true);
  left: 188vw;
  position: absolute;
  top: 55vh;
  z-index: 1;
}

.ucp-triangle--one,
.ucp-triangle--four {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.ucp-message--one {
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.2;
  padding-left: rem(30);
  text-transform: uppercase;

  span {
    display: block;
    font-size: rem(14);
    font-weight: 300;
  }

  .ucp-triangle {
    @include svg-standalone(18, theme-color(blue), normal, true);
    left: 0;
    position: absolute;
    top: rem(6);
  }

  @include media-breakpoint-up(md) {
    font-size: rem(25);
    left: 5vw;
    padding-left: rem(60);
    top: 27vh;

    span {
      font-size: rem(17);
    }

    .ucp-triangle {
      @include svg-standalone(34, theme-color(blue), normal, true);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(35);
    left: 10vw;
  }
}

.ucp-message--two {
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.2;
  padding-left: rem(30);
  text-transform: uppercase;

  span {
    display: block;
    font-size: rem(17);
    font-weight: 300;
  }

  .ucp-triangle {
    @include svg-standalone(18, theme-color(blue), normal, true);
    left: 0;
    position: absolute;
    top: rem(4);
  }

  @include media-breakpoint-up(md) {
    font-size: rem(28);
    left: 30vw;
    padding-left: rem(70);
    top: 47vh;

    .ucp-triangle {
      @include svg-standalone(45, theme-color(yellow), normal, true);
    }

    @include media-breakpoint-up(lg) {
      font-size: rem(34);
      left: 40vw;
    }
  }
}

.ucp-message--three {
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.2;
  padding-left: rem(30);
  text-transform: uppercase;

  span {
    display: block;
    font-size: rem(17);
    font-weight: 300;
  }

  .ucp-triangle {
    @include svg-standalone(18, theme-color(blue), normal, true);
    left: 0;
    position: absolute;
    top: rem(4);
  }

  @include media-breakpoint-up(md) {
    font-size: rem(34);
    left: 20vw;
    padding-left: rem(70);
    top: 66vh;

    .ucp-triangle {
      @include svg-standalone(24, theme-color(green), normal, true);
    }
  }
}

.ucp-message--four {
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.2;
  padding-left: rem(30);
  text-transform: uppercase;

  span {
    display: block;
    font-size: rem(17);
    font-weight: 300;
  }

  .ucp-triangle {
    @include svg-standalone(18, theme-color(blue), normal, true);
    left: 0;
    position: absolute;
    top: 0;

    @include media-breakpoint-up(lg) {
      top: rem(4);
    }
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    font-size: rem(28);
    left: 30vw;
    padding-left: rem(90);
    top: 80vh;

    .ucp-triangle {
      @include svg-standalone(50, theme-color(red), normal, true);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(40);
    left: 40vw;
  }
}

.ucp-message--six {
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.2;
  padding-left: rem(30);
  text-transform: uppercase;

  .ucp-text-italic {
    display: inline-block;
    font-style: italic;
    font-weight: 400;

    @include media-breakpoint-up(md) {
      font-size: rem(26);
      font-weight: 300;
    }
  }

  span {
    display: block;
    font-size: rem(17);
    font-weight: 300;
  }

  .ucp-triangle {
    @include svg-standalone(18, theme-color(blue), normal, true);
    left: 0;
    position: absolute;
    top: rem(4);
  }

  @include media-breakpoint-up(md) {
    font-size: rem(26);
    font-weight: 300;
    left: 7vw;
    padding-left: rem(70);
    top: 54vh;

    .ucp-triangle {
      @include svg-standalone(24, theme-color(green), normal, true);
    }
  }
}

.ucp-message--five {
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.2;
  padding-left: rem(30);
  text-transform: uppercase;

  span {
    display: block;
    font-size: rem(17);
    font-weight: 300;
  }

  .ucp-triangle {
    @include svg-standalone(18, theme-color(blue), normal, true);
    left: 0;
    position: absolute;
    top: rem(4);
  }

  @include media-breakpoint-up(md) {
    font-size: rem(32);
    left: 10vw;
    padding-left: rem(90);
    top: 36vh;

    .ucp-triangle {
      @include svg-standalone(45, theme-color(yellow), normal, true);
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(40);
    left: 20vw;
  }

  @include media-breakpoint-up(xl) {
    left: 30vw;
  }
}

.ucp-message--advance,
.ucp-message--advance-more-info,
.ucp-message--advance-links {
  color: theme-color(white);
  cursor: pointer;
  margin-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  position: absolute;

  .ucp-triangle {
    @include svg-standalone(160, theme-color(blue), normal, true);
    transform: rotate(90deg);
    transition: $transition-base;
  }

  .ucp-message-text {
    display: block;
    font-size: rem(20);
    line-height: 1.2;
    padding: rem(20) rem(18) 0 rem(18);
    position: absolute;
    text-align: center;
    top: 0;
    transition: $transition-base;
    white-space: normal;

    span {
      color: theme-color(yellow);
    }
  }

  @include media-breakpoint-up(md) {
    bottom: auto;
    opacity: 0;
    position: absolute;
    visibility: hidden;

    .ucp-triangle {
      @include svg-standalone(260, theme-color(blue), normal, true);
      transform: none;
    }

    .ucp-message-text {
      font-size: rem(30);
      padding: rem(60) 0 rem(50) rem(30);
      text-align: left;
    }
  }
}

.ucp-message--advance {
  bottom: rem(60);
  left: 50%;
  transform: translateX(-50%);

  @include media-breakpoint-up(md) {
    left: 70vw;
    top: 70vh;
    transform: translate(-50%, -50%);

    .ucp-triangle {
      @include svg-standalone(260, theme-color(blue), normal, true);
      transform: none;
    }

    .ucp-message-text {
      padding: rem(60) rem(60) 0 rem(30);
    }
  }
}

.ucp-message--advance-more-info {
  bottom: rem(180);
  left: 50%;
  transform: translateX(-50%);

  .ucp-triangle {
    @include svg-standalone(160, theme-color(red), normal, true);
  }

  .ucp-triangle--three {
    @include svg-standalone(24, theme-color(blue), normal, true);
  }

  @include media-breakpoint-up(md) {
    left: 70vw;
    top: 130vh;
    transform: translate(-50%, -50%);

    .ucp-triangle {
      @include svg-standalone(260, theme-color(blue), normal, true);
      transform: none;
    }

    .ucp-message-text {
      padding: rem(60) rem(60) 0 rem(30);
    }
  }
}

.ucp-message--advance-links {
  bottom: rem(120);
  cursor: pointer;
  pointer-events: all !important;
  left: 50%;
  transform: translateX(-50%);

  .ucp-message-text {
    color: theme-color(white);
    font-size: rem(20);
    padding: rem(30) rem(10) 0 rem(10);
    text-align: center;
    width: 100%;
  }

  .ucp-message-text--small {
    bottom: rem(68);
    color: theme-color(white);
    font-size: rem(12);
    line-height: 1.1;
    padding: 0 rem(36);
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }

  .ucp-triangle {
    @include svg-standalone(170, theme-color(green), normal, true);
  }

  .ucp-triangle--two {
    @include svg-standalone(24, theme-color(yellow), normal, true);
    bottom: rem(20);
    left: rem(100);
  }

  .ucp-triangle--three {
    @include svg-standalone(20, theme-color(blue), normal, true);
    left: rem(80);
  }

  @include media-breakpoint-up(md) {
    bottom: auto;
    left: auto;
    max-width: none;
    right: 56vw;
    top: 20vh;
    transform: none;

    .ucp-triangle {
      @include svg-standalone(260, theme-color(red), normal, true);
    }

    .ucp-triangle--two {
      @include svg-standalone(44, theme-color(yellow), normal, true);
      left: rem(180);
      top: rem(200);
    }

    .ucp-message-text {
      font-size: rem(28);
      padding: rem(92) rem(40) 0 rem(26);
      text-align: left;
    }

    .ucp-message-text--small {
      bottom: rem(80);
      font-size: rem(16);
      padding: 0 rem(20) 0 rem(26);
      text-align: left;
    }
  }
}

@keyframes hideSkip {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes skip {
  0% { transform: translateX(0); }
  80% { transform: translateX(#{rem(10)}); }
  100% { opacity: 0; }
}

.ucp-skip-animation {
  animation: hideSkip 2s forwards;
  animation-delay: 8.5s;
  bottom: rem(40);
  color: theme-color(gray);
  cursor: pointer;
  font-size: rem(18);
  padding-right: rem(20);
  position: absolute;
  right: rem(100);
  transition: opacity .2s ease-in-out;

  .ucp-end-animation & {
    opacity: 0;
  }

  &::before {
    @include svg-standalone(14, theme-color(black), outline-white, true);
    animation: skip 2s infinite;
    content: '';
    position: absolute;
    right: 0;
    top: rem(8);
    transform: rotate(90deg);
  }
}

.ucp-form__information_labels {
  font-size: rem(20);
  font-weight: 300;
  line-height: 1.2;
  max-width: rem(200);
  position: relative;

  @include media-breakpoint-up(md) {
    position: absolute;
    left: calc(100vw - #{rem(240)});
    text-align: right;
    top: rem(30);
    width: 100%;
  }
}

.ucp-form__information_label-one {
  display: block;

  &::before {
    @include svg-standalone(12, theme-color(blue), normal, true);
    content: '';
    left: rem(14);
    position: absolute;
    top: rem(8);
  }
}

.ucp-form__information_label-one {
  display: block;
}

.ucp-form__information_label-two {
  display: block;
  font-weight: 400;
}

.ucp-form__information_label-three {
  display: block;
  font-weight: 400;
}

.ucp-rectangle-button {
  border-radius: rem(15);
  cursor: pointer;
  display: block;
  margin: rem(15) auto;
  width: rem(250);

  @include media-breakpoint-up(md) {
    margin: rem(15) rem(10);
    max-width: none;
    min-width: rem(240);
  }

  .text {
    color: theme-color(white);
    display: block;
    font-size: rem(16);
    font-weight: 400;
    line-height: 2.5;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: rem(20);
    }
  }
}

.blue {
  background-color: theme-color(blue);
}

.red {
  background-color: theme-color(red);
}
.green {
  background-color: theme-color(green);
}

</style>
